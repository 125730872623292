import React from 'react'
import ReactDOM from 'react-dom'
import { Amplify } from 'aws-amplify'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import App from './App'
import pkg from '../package.json'
import { log } from './util'
import { theme } from './theme'
import { AdminAccessProvider } from './useAdminAccess'
import './index.css'

import reportWebVitals from './reportWebVitals'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const LOG_CAT = 'init'

log.info(LOG_CAT, `[${pkg.name}::${pkg.version}::${process.env.CODEBUILD_BUILD_NUMBER || 'local'}]`)

// Init error logging
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `simpleub-water@${pkg.version}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})

log.info(LOG_CAT, 'Sentry Setup')

// Init session logging
if (process.env.REACT_APP_LOGROCKET_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID)
}

log.info(LOG_CAT, 'LogRocket Setup')

/** Amplify Config */
/*
Amplify can be configured via the Amplify CLI, which creates and manages AWS resources. When using the Amplify CLI, 
the amplifyconfiguration.json file gets created and updated automatically for you based upon the resources you have added and configured.
This configuration is a copy of that file. 

It was created by running:
- amplify init
- amplify import auth
- amplify push

I copied the resulting amplifyconfiguration.json then deleted the amplify app with "amplify delete"

Why? The amplifyconfiguration.json allows us to include specific password requirements. But we create our resources with Terraform,
so I didn't want to leave an open door for resources to get created here. We also aren't set up to use our dev/prod account environments with Amplify in a CICD flow. 

Related docs:
- https://docs.amplify.aws/javascript/build-a-backend/auth/import-existing-resources/
- https://docs.amplify.aws/angular/build-a-backend/auth/set-up-auth/

This documentation shows the alternative non-amplify cli configuration object available. It does not include password requirement configuration.
https://docs.amplify.aws/javascript/tools/libraries/configure-categories/#authentication-amazon-cognito
*/
const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_APP_ID,
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_SYMBOLS']
  },
  aws_cognito_verification_mechanisms: ['EMAIL']
}

// Configure AWS Cognito authorization
Amplify.configure(amplifyConfig)

log.info(LOG_CAT, 'Amplify Configured')

log.info(LOG_CAT, 'Auth Configured')

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AdminAccessProvider>
          <App />
        </AdminAccessProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
