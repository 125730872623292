/** @file Default error boundary */

import * as React from 'react'
import * as Sentry from '@sentry/browser'

/**
 * Default ErrorBoundary
 */
export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  /**
   * @class
   * @param {object} props - Component properties
   */
  constructor(props: Object) {
    super(props)
    this.state = { hasError: false }
  }

  /** Update state
   *
   * @returns {void}
   */
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  /**
   * Catch error
   *
   * @param {Error} error - Original error
   * @param {object} errorInfo - Additional error information
   */
  componentDidCatch(error: Error, errorInfo: Object) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error)
    console.error({ error, errorInfo })
  }

  /**
   * Render
   *
   * @returns {React.Component} - Child component or error component
   */
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}
