/** @file Usage utility */

import { endOfMonth, getUnixTime, sub, startOfMonth } from 'date-fns'

import type { Usage as UsageType } from './types'

export const startOfLastMonth = getUnixTime(
  startOfMonth(sub(new Date(), { months: 1 }))
)

export const endOfLastMonth = getUnixTime(
  endOfMonth(sub(new Date(), { months: 1 }))
)

/**
 * Get total volume of objects in object
 *
 * @param  {object} [usageObj={}] - Usage object {dateStr: {volume: number}}
 * @returns {number} - Total volume
 */
export const getTotalVolume = (
  usageObj?: { volume: number, date: string }[]
): number =>
  // $FlowExpectedError - Mixed coming out of reduce
  Object.values(usageObj || {}).reduce((acc, cur) => acc + cur.volume, 0)

/**
 * Determine if a given usage is for a device and within a date range
 *
 * @param {object} usage - Usage object
 * @param {string} deviceId - Device ID
 * @param {number} start - Unix timestamp for start of date range
 * @param {number} end - Unix timestamp for end of date range
 * @returns {boolean} - Whether or not the given usage is for the device and within the date range
 */
export const isValidUsage = (
  usage: UsageType,
  deviceId: string,
  start: number,
  end: number
): boolean => {
  const [usageDeviceId, timeId] = usage.id.split('-')
  const time = Number(timeId)

  if (usageDeviceId === deviceId && time >= start && time <= end) {
    return true
  } else {
    return false
  }
}
