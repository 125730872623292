import * as React from 'react'
import { Button, IconButton, Typography, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex'
  },
  button: {
    height: 'fit-content'
  },
  editIcon: {
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  inputRoot: {
    fontSize: '3rem',
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: '1.167'
  },
  deleteButton: {
    color: theme.palette.getContrastText(theme.palette.warning.main),
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    },
  },
}))

interface NameProps {
  name: string
  onUpdate: (value: string) => Promise<any>
  onDelete: () => void
}

/**
 * Name display/edit
 *
 * @param {object} props - Component properties
 * @param {string} props.name     - Name
 * @param {Function} props.onUpdate - Update function
 * @returns {React.Component} - Name
 */
export const Name = ({ name, onUpdate, onDelete }: NameProps): JSX.Element => {
  const classes = useStyles()
  const [localName, setLocalName] = React.useState(name)
  const [isEditing, setIsEditing] = React.useState(false)

  React.useEffect(() => {
    setLocalName(name)
    setIsEditing(false)
  }, [name])

  return (
    <div className={classes.root}>
      {!isEditing ? (
        <React.Fragment>
          <Typography variant="h4">{localName}</Typography>
          <IconButton onClick={() => setIsEditing(true)} className={classes.editIcon} size="large">
            <EditIcon />
          </IconButton>
          <Button
            className={classes.deleteButton}
            color="warning"
            startIcon={<DeleteForeverIcon />}
            onClick={onDelete}
          >
            Delete
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TextField
            fullWidth={true}
            value={localName}
            onChange={(evt) => setLocalName(evt.target.value)}
            inputProps={{
              className: classes.inputRoot
            }}
          />
          <IconButton
            onClick={() => {
              onUpdate(localName).then(() => setIsEditing(false))
            }}
            className={classes.button}
            size="large"
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setLocalName(name)
              setIsEditing(false)
            }}
            className={classes.button}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      )}
    </div>
  )
}
