import React, { createContext, useState, useContext, ReactNode } from 'react'
import ModalLoading from '.'

interface ModalContextType {
  showLoading: (title: string, description: string) => void
  hideLoading: () => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

// Proveedor del contexto del modal
export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({
    title: 'Cargando...',
    description: 'Por favor, espera.'
  })

  // Función para mostrar el modal
  const showLoading = (title: string, description: string) => {
    setModalContent({ title, description })
    setModalOpen(true)
  }

  // Función para ocultar el modal
  const hideLoading = () => {
    setModalOpen(false)
  }

  return (
    <ModalContext.Provider value={{ showLoading, hideLoading }}>
      {children}
      {/* El modal está aquí, siempre disponible */}
      <ModalLoading open={modalOpen} title={modalContent.title} description={modalContent.description} />
    </ModalContext.Provider>
  )
}

// Hook para acceder al contexto
export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal debe usarse dentro de un ModalProvider')
  }
  return context
}
