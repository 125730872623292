import * as React from 'react'
import { Button, IconButton, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Check as CheckIcon,
  DeleteForever as DeleteForeverIcon,
  Close as CloseIcon,
  Edit as EditIcon
} from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  button: {
    height: 'fit-content'
  },
  editIcon: {
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  inputRoot: {
    fontSize: '3rem',
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: '1.167'
  },
  deleteButton: {
    color: theme.palette.getContrastText(theme.palette.warning.main),
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  }
}))

interface NameProps {
  name: string
  onUpdate: (value: string) => Promise<any>
  onDelete: () => void
}

export const Name = ({ name, onUpdate, onDelete }: NameProps): JSX.Element => {
  const classes = useStyles()
  const [localName, setLocalName] = React.useState(name)
  const [isEditing, setIsEditing] = React.useState(false)

  React.useEffect(() => {
    setLocalName(name)
    setIsEditing(false)
  }, [name])

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!isEditing ? (
        <>
          <Typography variant="h4">{localName}</Typography>
          <IconButton onClick={() => setIsEditing(true)} className={classes.editIcon} size="large">
            <EditIcon />
          </IconButton>
          <Button className={classes.deleteButton} color="warning" startIcon={<DeleteForeverIcon />} onClick={onDelete}>
            Delete
          </Button>
        </>
      ) : (
        <>
          <TextField
            fullWidth={true}
            value={localName}
            onChange={(evt) => setLocalName(evt.target.value)}
            margin="dense"
            inputProps={{
              className: classes.inputRoot
            }}
          />
          <IconButton
            onClick={() => {
              setIsEditing(false)
              onUpdate(localName)
            }}
            className={classes.button}
            size="large"
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setLocalName(name)
              setIsEditing(false)
            }}
            className={classes.button}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </>
      )}
    </div>
  )
}
