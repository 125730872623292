import * as React from 'react'
import { Heading, useTheme } from '@aws-amplify/ui-react'

const SignIn = {
  Header: () => {
    const { tokens } = useTheme()
    return (
      <Heading padding={`${tokens.space.xl} ${tokens.space.xl} 0 ${tokens.space.xl}`} level={3} textAlign="center">
        Admin Login
      </Heading>
    )
  }
}

export default SignIn
