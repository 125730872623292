import * as React from 'react'
import { Stack, Box, Divider, Alert, Typography } from '@mui/material'
import { EditableText } from '@/components/EditableText'
import { createStripeAccount, createStripeAccountLink, getStripeFeature, getUserInfo, updateUserInfo } from './api'
import InitialLoader from '@/components/Loader/InitialLoader'
import { IUserData, STRIPE_ACCOUNT_LINK_TYPE } from './types'
import StripeCard from '@/components/StripeCard'

const AccountSettings = () => {
  const [userData, setUserData] = React.useState<IUserData>()
  const [idUser, setIdUser] = React.useState<string>('')
  const [canConnectStripe, setCanConnectStripe] = React.useState<boolean>(false)

  React.useEffect(() => {
    const getData = async () => {
      const userInfo = await getUserInfo()
      const stripeInfo = await getStripeFeature()
      if (stripeInfo) setCanConnectStripe(stripeInfo.features.includes('BILLING'))

      if (userInfo) {
        setUserData({
          orgName: userInfo.user.organization_name,
          connectedAccountId: userInfo.user.stripe_connected_account_id,
          connectedAccountDetailsSubmitted: userInfo.user.stripe_connected_account_details_submitted
        })
        setIdUser(userInfo.user.id)
      }
    }
    if (userData === undefined) getData()
  }, [userData])

  const handleSubmit = async (newOrgName: string) => {
    const updatedInfo = await updateUserInfo({ organization_name: newOrgName }, idUser)
    if (updatedInfo) {
      setUserData({
        orgName: updatedInfo.user.organization_name,
        connectedAccountId: updatedInfo.user.stripe_connected_account_id,
        connectedAccountDetailsSubmitted: updatedInfo.user.stripe_connected_account_details_submitted
      })
    }
  }

  const handleCreateStripeAccount = async () => {
    const userInfo = await createStripeAccount()
    if (userInfo) {
      setUserData({
        orgName: userInfo.user.organization_name,
        connectedAccountId: userInfo.user.stripe_connected_account_id,
        connectedAccountDetailsSubmitted: userInfo.user.stripe_connected_account_details_submitted
      })
    }
    return await handleCreateStripeAccountLink()
  }

  const handleCreateStripeAccountLink = async () => {
    const { url } = await createStripeAccountLink({ link_type: STRIPE_ACCOUNT_LINK_TYPE.ACCOUNT_ONBOARDING })
    if (url) {
      return url
    }
    return ''
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      {!userData ? (
        <InitialLoader />
      ) : (
        <Stack spacing={5}>
          <Box sx={{ fontSize: '36px', fontWeight: 'bold' }}>Settings</Box>
          <Stack direction="row" spacing={5} alignItems={'center'}>
            <Box sx={{ fontSize: '24px', fontWeight: 'bold' }}>Organization Name: </Box>
            {userData && userData.orgName && <EditableText value={userData.orgName} onUpdate={handleSubmit} />}
          </Stack>
          <Divider />
          {userData.connectedAccountId !== '' && userData.connectedAccountDetailsSubmitted && (
            <Alert icon={false} variant="filled" sx={{ bgcolor: '#ffecb2', color: 'black', width: '90%' }}>
              <Stack direction={'row'} gap={1}>
                <Typography>
                  A default billing contact has been assigned for each unit. You can update the recipient anytime in the
                  Tenant tab for each building.
                </Typography>
              </Stack>
            </Alert>
          )}
          {canConnectStripe && (
            <StripeCard
              accountLink={handleCreateStripeAccountLink}
              stripeUserID={userData.connectedAccountId}
              setUpFinished={userData.connectedAccountDetailsSubmitted}
              connectAccount={handleCreateStripeAccount}
            />
          )}
        </Stack>
      )}
    </Box>
  )
}

export default AccountSettings
