// @flow
/** @file API related utilities */

import { v4 as uuid } from 'uuid'

/**
 * Create default headers for API communication
 *
 * @param {object} headerData - Data for headers
 * @param  {string} headerData.authToken - Auth token
 * @param  {string} headerData.requestId - Optional request ID
 * @returns {object} - Object of header data
 */
export const createDefaultHeaders = ({
  authToken,
  requestId
}: {
  authToken: string,
  requestId?: string
}) =>
  new Headers({
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
    'request-id': requestId || uuid()
  })

export const createAxiosHeaders = ({
  authToken,
  requestId
}: {
  authToken: string,
  requestId?: string
}) =>
  ({
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
    'request-id': requestId || uuid()
  })

/**
 * Throw response if it contains error
 *
 * @param {Response} resp - Response
 * @returns {Response} - Passed in response
 */
export const throwIfError = (resp: Response): Response => {
  if (!resp.ok || resp.status < 200 || resp.status >= 400) {
    throw resp
  }

  return resp
}

/**
 * Throws Error if response is not ok, else return response json
 * Retrieves the error text before throwing Error
 *
 * @param {Response} resp - Response
 * @returns {any} - Result of json()
 */
export const getJSONOrThrow = (resp: Response) => {
  if (!resp.ok || resp.status < 200 || resp.status >= 400) {
    return resp.text().then(text => {throw new Error(text)})
  }

  return resp.json()
}
