/** @file Auth/user logic */

import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

const API_URL = process.env.REACT_APP_API_URL

export const getSession = async (): Promise<any> => {
  const sessionValues = ((await fetchAuthSession()).tokens) as any
  const adminInfoStr = window.localStorage.getItem('adminInfo') || '{}'
  const adminInfo = JSON.parse(adminInfoStr)
  sessionValues.adminEmail = adminInfo.adminEmail
  return sessionValues
}

/**
 * Get current user ID
 *
 * @returns {string} - Current user ID
 */
export const getUserId = async () => {
  const userInfo = await getCurrentUser()

  return userInfo.userId
}

export const urlWithAdminQuery = (url: string, adminEmail: string) => {
  let output = API_URL + url;
  if (adminEmail) {
    url.includes('?') ? output += '&' : output += '?'
    
    return `${output}admin_email=${encodeURIComponent(adminEmail)}`
  } 
  
  else return output
}
