import { get as getUsage } from '@/Usage/api'
import { ArrowLeft as ArrowLeftIcon, ArrowRight as ArrowRightIcon } from '@mui/icons-material'
import { DateRange } from '@mui/lab/DateRangePicker'
import { Box, IconButton, LinearProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  addDays,
  differenceInDays,
  endOfDay,
  endOfMonth,
  format,
  getUnixTime,
  isFuture,
  isToday,
  startOfDay,
  startOfMonth,
  subDays
} from 'date-fns'
import { useEffect, useState } from 'react'
import 'react-day-picker/dist/style.css'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { getTotalVolume } from '../../../Usage/util'
import { Chart } from '../../../components/Chart'
import { createLabel as createChartLabel } from '../../../components/Chart/util'
import { ErrorBoundary } from '../../../components/ErrorBoundary'
import { addCreate, convertTimestampToTimeZone } from '../../../util'
import { useStructure } from '../../useStructures'
import { generateUsageCSV } from '../../util'

// Types
import { defaultOutageStatusResponse, getOutageStatus } from '@/Outage/api'
import {
  OUTAGE_STATUS_REFRESH_MILLIS,
  OUTAGE_STATUS_SWR_KEY,
  mapOutageStatusToIsElasticsearchOutage
} from '@/Outage/util'
import theme from '../../../theme'

import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import useMixPanel from '@/hooks/useMixPanel'
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD'

dayjs.extend(utc)
dayjs.extend(timezone)

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  header: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'left'
  },
  chartBody: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  chartLoader: {
    left: '20%',
    position: 'absolute',
    top: '50%',
    width: '60%'
  },
  selectedDays: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.main
  },
  editIcon: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  prevNextButton: {
    height: 'fit-content'
  },
  nextButton: {
    marginLeft: theme.spacing(1)
  },
  prevButton: {
    marginRight: theme.spacing(1)
  }
}))

const today = new Date()
const defaultSelected: DateRange<Date> = [startOfMonth(today), endOfMonth(today)]

interface IProps {
  timeZone: string
}

export const UsageTab = ({ timeZone }: IProps): JSX.Element => {
  const classes = useStyles()

  const [graphData, setGraphData] = useState([{ label: '', gallons: 0, volume: 0, date: format(today, 'yyyy-MM-dd') }])
  const [moveByMonth, setMoveByMonth] = useState(true)
  const [range, setRange] = useState<DateRange<Date>>(defaultSelected)
  const [canGoNext, setCanGoNext] = useState(false)
  const { trackEvent } = useMixPanel()
  const [eventData, setEventData] = React.useState<{}>()
  const { structureId } = useParams()
  if (!structureId) {
    throw new Error('MISSING_REQUIRED_ROUTE_DATA')
  }

  // Data fetching ////////////////////

  const { structure } = useStructure(structureId)

  React.useEffect(() => {
    if (structure && range) {
      trackEvent('Property Usage Viewed', {
        PropertyID: structureId,
        PropertyName: structure.name,
        StartDate: convertTimestampToTimeZone(getUnixTime(startOfDay(range[0]!)), undefined),
        EndDate: convertTimestampToTimeZone(getUnixTime(startOfDay(range[1]!)), undefined)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structure, structureId, range])

  /** For request that should be cached for a day */
  const STATIC_REQUEST_DEDUP_INTERVAL = 86400000 // Milliseconds in a day

  const getRawUsageId = () => {
    return structure && range[0] && range[1] && structure.units && structure.units.length > 0
      ? `structures/${structureId}/usage?start=${startOfDay(range[0]).getTime()}&end=${endOfDay(range[1]).getTime()}`
      : null
  }

  const { data: outageStatusResponse = defaultOutageStatusResponse } = useSWR(OUTAGE_STATUS_SWR_KEY, getOutageStatus, {
    refreshInterval: OUTAGE_STATUS_REFRESH_MILLIS
  })

  // Load usage
  const { data: rawUsage } = useSWR(
    getRawUsageId,
    () =>
      Promise.all(
        structure!.units.map((unit) =>
          getUsage(
            unit.id,
            range[0]!,
            range[1]!,
            mapOutageStatusToIsElasticsearchOutage(outageStatusResponse),
            timeZone
          )
        )
      ),
    { dedupingInterval: STATIC_REQUEST_DEDUP_INTERVAL }
  )

  // Set graph data when rawUsage changes
  useEffect(() => {
    const usageByDate = {}

    rawUsage?.forEach((usage) => {
      usage.devices?.forEach((device) => {
        device.daily_usages.forEach((usageData) => {
          addCreate(usageByDate, usageData.date, usageData.volume)
        })
      })
    })

    const cleanedUsage = Object.entries(usageByDate).map((u) => {
      return {
        label: createChartLabel(u[1] as number, u[0]),
        gallons: u[1] as number,
        volume: u[1] as number,
        date: u[0]
      }
    })
    if (cleanedUsage.length) {
      setGraphData(cleanedUsage)
      setEventData({
        PropertyID: structureId,
        PropertyName: structure?.name,
        WaterUsage: getTotalVolume(cleanedUsage),
        StartDate: convertTimestampToTimeZone(getUnixTime(startOfDay(range[0]!)), undefined),
        EndDate: convertTimestampToTimeZone(getUnixTime(startOfDay(range[1]!)), undefined)
      })
    } else {
      setGraphData([{ label: '', gallons: 0, volume: 0, date: format(range[0]!, 'yyyy-MM-dd') }])
      setEventData({
        PropertyID: structureId,
        PropertyName: structure?.name,
        WaterUsage: getTotalVolume([{ volume: 0, date: format(range[0]!, 'yyyy-MM-dd') }]),
        StartDate: convertTimestampToTimeZone(getUnixTime(startOfDay(range[0]!)), undefined),
        EndDate: convertTimestampToTimeZone(getUnixTime(startOfDay(range[1]!)), undefined)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawUsage, range])

  // En/disable next button
  useEffect(() => {
    if (range[1]) {
      if (isFuture(range[1]) || isToday(range[1])) {
        setCanGoNext(false)
      } else {
        setCanGoNext(true)
      }
    }
  }, [range])

  const handleClickNext = () => {
    if (moveByMonth) {
      const newMonth = addDays(range[1]!, 3)
      setRange([startOfDay(startOfMonth(newMonth)), startOfDay(endOfMonth(newMonth))])
    } else {
      const numberOfDaysInRange = differenceInDays(range[1]!, range[0]!)

      const newFrom = addDays(range[0]!, numberOfDaysInRange + 1)
      const newTo = addDays(range[1]!, numberOfDaysInRange + 1)

      setRange([startOfDay(newFrom), startOfDay(newTo)])
    }
  }

  const handleClickPrev = () => {
    if (moveByMonth) {
      const newMonth = subDays(range[0]!, 3)
      setRange([startOfDay(startOfMonth(newMonth)), startOfDay(endOfMonth(newMonth))])
    } else {
      const numberOfDaysInRange = differenceInDays(range[1]!, range[0]!)

      const newFrom = subDays(range[0]!, numberOfDaysInRange + 1)
      const newTo = subDays(range[1]!, numberOfDaysInRange + 1)

      setRange([startOfDay(newFrom), startOfDay(newTo)])
    }
  }

  const handleDateChange = (dates) => {
    const startDateWithDayJs = dayjs(dates[0])
    const endDateWithDayJs = dayjs(dates[1])

    if (
      startDateWithDayJs.isSame(endDateWithDayJs.startOf('month'), 'day') &&
      endDateWithDayJs.isSame(endDateWithDayJs.endOf('month'), 'day')
    ) {
      setMoveByMonth(true)
    } else {
      setMoveByMonth(false)
    }

    const startDate = dayjs.tz(dates[0], timeZone).toDate()
    const endDate = dayjs.tz(dates[1], timeZone).toDate()
    setRange([startDate, endDate])
  }

  /** Whether or not usage is being fetched */
  const isLoadingUsage = !graphData

  return (
    <ErrorBoundary>
      <div data-component-id={'STRUCTURE-VIEW'} className={classes.wrapper}>
        {structure && (
          <div className={classes.container}>
            {/* Chart */}
            <Box position="relative">
              <Box>
                <Box textAlign="center" className="custom-range-picker" marginBottom="15px">
                  <RangePicker
                    format={'MMM D, YYYY'}
                    allowClear={false}
                    maxDate={dayjs(format(endOfMonth(today), 'yyyy-MM-dd'), dateFormat)}
                    onChange={(value, dateString) => handleDateChange(dateString)}
                    value={
                      range && range[0] && range[1]
                        ? [
                            dayjs(format(range[0], 'yyyy-MM-dd'), dateFormat),
                            dayjs(format(range[1], 'yyyy-MM-dd'), dateFormat)
                          ]
                        : undefined
                    }
                    presets={[
                      {
                        label: <span aria-label="Current month">Current month</span>,
                        value: () => [dayjs().startOf('month'), dayjs().endOf('month')]
                      }
                    ]}
                  />
                </Box>
                <Typography variant="h6" align="center" color={theme.palette.grey[700]}>
                  {Math.floor(getTotalVolume(graphData))} gal
                </Typography>
              </Box>
              <Box className={classes.chartBody}>
                <IconButton
                  size="large"
                  className={classNames(classes.prevNextButton, classes.prevButton)}
                  onClick={handleClickPrev}
                >
                  <ArrowLeftIcon fontSize="large" />
                </IconButton>
                <Box sx={{ opacity: isLoadingUsage ? 0.2 : undefined, display: 'flex', flexDirection: 'column' }}>
                  <Chart
                    data={graphData || []}
                    x={'date'}
                    y={'gallons'}
                    createCSV={() =>
                      Promise.resolve(
                        generateUsageCSV(rawUsage, {
                          start: getUnixTime(range[0]!),
                          end: getUnixTime(range[1]!),
                          // @ts-ignore - timeZoneName not recognized
                          timezone: new Date().toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ')[2]
                        })
                      )
                    }
                    eventType="Property Usage Exported"
                    eventData={eventData}
                    csvTitle={`${
                      moveByMonth
                        ? format(startOfMonth(range[0]!), 'MM_dd_yyyy')
                        : `${format(range[0]!, 'MM_dd_yyyy')}-${format(range[1]!, 'MM_dd_yyyy')}`
                    }_${structure.name}_usage`}
                  />
                </Box>
                <IconButton
                  size="large"
                  className={classNames(classes.prevNextButton, classes.nextButton)}
                  onClick={handleClickNext}
                  disabled={!canGoNext}
                >
                  <ArrowRightIcon fontSize="large" />
                </IconButton>
                {isLoadingUsage && (
                  <div className={classes.chartLoader}>
                    <LinearProgress />
                  </div>
                )}
              </Box>
            </Box>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}
