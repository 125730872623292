import { useParams } from 'react-router-dom'
import { useStructure } from '../useStructures'
import { useEffect, useState } from 'react'
import { ServerDevice } from '@/Devices/types'
import { DeviceStatusTable } from '@/components/DeviceStatusTable'

export const AllDevices = () => {
  const [allDevices, setAllDevices] = useState<ServerDevice[]>([])
  const { structureId } = useParams()
  if (!structureId) {
    throw new Error('MISSING_REQUIRED_ROUTE_DATA')
  }

  const { structure, mutate: updateStructure } = useStructure(structureId)

  const resfreshData = async () => {
    if (structure) {
      await updateStructure()
    }
  }

  useEffect(() => {
    if (structure) {
      let devices: ServerDevice[] = []
      structure.units.forEach((item) =>
        devices.push(...item.devices.map((device) => ({ ...device, unit_name: item.name, unit_id: item.id })))
      )
      setAllDevices(devices)
    }
  }, [structure])

  return (
    <>
      {structure && allDevices && (
        <DeviceStatusTable
          devices={allDevices}
          refreshData={() => resfreshData()}
          activateMultipleSelectors
          timeZone={structure?.timezone_key || ''}
          structure={structure}
        />
      )}
    </>
  )
}
