/** @file Section of Structure Form that reviews what's about to be created */

import makeStyles from '@mui/styles/makeStyles'
import * as React from 'react'
import Typography from '@mui/material/Typography'

import type { Address } from '../../../types'
import type { Unit } from '../../../Units/types'

const labelValueClasses: () => {
  container: string
  label: string
} = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline'
  },
  label: {
    marginRight: theme.spacing(1)
  }
}))

type LabelValueProps = {
  label: string
  value: string
}

/**
 * Label/Value component (just typography/layout)
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Label/Value text
 */
const LabelValue = (props: LabelValueProps) => {
  const classes = labelValueClasses()

  return (
    <div className={classes.container}>
      <Typography variant={'h6'} className={classes.label}>{`${props.label}: `}</Typography>
      <Typography variant={'body1'}>{props.value}</Typography>
    </div>
  )
}

const useStyles: () => {
  addButton: string
  card: string
  divider: string
  root: string
  table: string
  textHeader: string
  content: string
} = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  divider: {
    margin: `${theme.spacing(2)} 0`
  },
  table: {
    marginTop: theme.spacing(2)
  },
  textHeader: {
    textAlign: 'center',
    marginTop: '10px',
    padding: '0 50px'
  },
  addButton: {
    color: 'white'
  },
  card: {
    margin: theme.spacing(1)
  },
  content: {
    padding: '15px',
    marginTop: '15px'
  }
}))

type Props = {
  // onAddAnother: () => void,
  structureAddress: Address
  structureName: string
  units: Unit[]
}

export const Review = (props: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.textHeader}>
          <Typography variant="h4">{`Review`}</Typography>
          <Typography variant="subtitle1">
            Please verify the below information. If you need to make changes, please go back.
          </Typography>
        </div>
      </div>
      <div className={classes.content}>
        <LabelValue label={'Property'} value={props.structureName} />
        <LabelValue
          label={'Address'}
          value={`${props.structureAddress.street} ${props.structureAddress.street2 || ''}, ${
            props.structureAddress.city
          } ${props.structureAddress.state} ${props.structureAddress.zip}`}
        />
        <LabelValue label={'Units'} value={''} />
        <ul>
          {props.units.map(({ name }, index) => (
            <li>
              {name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
