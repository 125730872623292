// @flow
/** @file Section of Structure Form that reviews what's been created and offers the option to add more */

import { Button } from '../../../components/Button'
import makeStyles from '@mui/styles/makeStyles'
import * as React from 'react'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'

import type { Address } from '../../../types'
import type { Unit } from '../../../Units/types'

// Label/Value ////////////////////////////////////////////////////////////////

const labelValueClasses: () => {
  container: string
  label: string
} = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline'
  },
  label: {
    marginRight: theme.spacing(1)
  }
}))

type LabelValueProps = {
  label: string
  value: string
}

/**
 * Label/Value component (just typography/layout)
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Label/Value text
 */
const LabelValue = (props: LabelValueProps) => {
  const classes = labelValueClasses()

  return (
    <div className={classes.container}>
      <Typography variant={'body1'} className={classes.label}>
        <b> {`${props.label}: `}</b>
      </Typography>
      <Typography variant={'body1'}>{props.value}</Typography>
    </div>
  )
}

// Component //////////////////////////////////////////////////////////////////

/**
 * Classname/style generator
 */
const useStyles: () => {
  addButton: string
  card: string
  divider: string
  headerContainer: string
  root: string
  table: string
  textHeader: string
  content: string
} = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  divider: {
    margin: `${theme.spacing(2)} 0`
  },
  table: {
    marginTop: theme.spacing(2)
  },
  headerContainer: {
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'end',
    marginBottom: theme.spacing(1)
  },
  addButton: {
    color: 'white'
  },
  card: {
    margin: theme.spacing(1)
  },
  textHeader: {
    textAlign: 'center',
    marginTop: '10px'
  },
  content: {
    padding: '15px',
    marginTop: '15px'
  }
}))

type Props = {
  onAddAnother: () => void
  structureAddress: Address
  structureName: string
  units: Unit[]
}

export const StructureCreated = (props: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Button color={'secondary'} className={classes.addButton} onClick={props.onAddAnother}>
          Add Another
          <AddIcon />
        </Button>
      </div>
      <div className={classes.textHeader}>
        <Typography variant="h4">Property Created!</Typography>
        <Typography variant="subtitle1">Congratulations on adding a new property.</Typography>
      </div>
      <div className={classes.content}>
        <LabelValue label={'Property'} value={props.structureName} />
        <LabelValue
          label={'Address'}
          value={`${props.structureAddress.street} ${props.structureAddress.street2 || ''}, ${
            props.structureAddress.city
          } ${props.structureAddress.state} ${props.structureAddress.zip}`}
        />
        <LabelValue label={'Units'} value={''} />
        <ul>
          {props.units.map(({ name }, index) => (
            <li>{name}</li>
          ))}
        </ul>
        <Typography variant="subtitle1">
          We've also turned on leak alerts for each unit. Once you connect your devices, you will receive alerts for
          both leaks and high flow leaks.
        </Typography>
      </div>
    </div>
  )
}
