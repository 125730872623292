import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@mui/material'
import { Business as BusinessIcon } from '@mui/icons-material'
import { useStructures } from '../../Structures/useStructures'
import { createStructureRoute } from '../../app-routes'
import StatusCard from '../../components/StatusCard'
import { Structure } from '../../Structures/types'
import { DeviceStatus } from '@/types'
import React from 'react'
import { getUserInfo, updateUserInfo } from '../AccountSettings/api'
import { toast } from 'react-toastify'
import { SWLoadingButton } from '@/components/LoadingButton'

const getStructureStatus = (structure: Structure): DeviceStatus => {
  let status: DeviceStatus = 'OKAY'
  let allDevices = 0
  structure.units.forEach((unit) => {
    allDevices = unit.devices.length + allDevices
  })
  if (!allDevices) {
    status = 'NO_DEVICES'
    return status
  }
  structure.units.forEach((unit) => {
    if (unit.devices.some((device) => device.device_status === 'ERROR')) {
      status = 'ERROR'
      return status
    }
    if (unit.devices.some((device) => device.device_status === 'WARNING')) {
      status = 'WARNING'
      return status
    }
  })

  return status
}

const getStructureErrorMessage = (structure: Structure, status: DeviceStatus): string[] => {
  let errMsg: string[] = []
  structure.units.forEach((unit) => {
    unit.devices.forEach((device) => {
      errMsg.push(`${unit.name} · ${device.name} · ${device.device_status_strings.join(',')}`)
    })
  })

  return errMsg
}

const Home = () => {
  const { structures } = useStructures()
  const [orgName, setOrgName] = React.useState<string>()
  const [idUser, setIdUser] = React.useState<string>('')
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    const getData = async () => {
      const userInfo = await getUserInfo()
      setOrgName(userInfo.user.organization_name)
      setIdUser(userInfo.user.id)
      if (userInfo.user.organization_name === '') setOpen(true)
    }
    if (orgName === undefined) getData()
  }, [orgName])

  const handleSubmit = async () => {
    if (orgName === '') toast.warn('The field Organization Name is required')
    if (orgName !== undefined && orgName !== '') {
      setLoading(true)
      await updateUserInfo({ organization_name: orgName }, idUser)
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {structures &&
          structures.map((structure: Structure) => {
            const status = getStructureStatus(structure)

            return (
              <Grid item xs="auto">
                <StatusCard
                  key={structure.id}
                  link={createStructureRoute(structure.id)}
                  title={structure.name}
                  subtitle={`${structure.units.length} Units`}
                  status={status}
                  icon={<BusinessIcon sx={{ width: 60, height: 60 }} />}
                  errorMessage={getStructureErrorMessage(structure, status)}
                />
              </Grid>
            )
          })}
      </Grid>
      <Dialog open={open}>
        <DialogTitle>
          <Box fontSize={'42px'} fontWeight={'bold'} textAlign={'center'}>
            Create Organization
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box textAlign={'center'} fontSize={'18px'} color={'black'}>
              Please provide the name of your organization. This will be shown to tenants when sharing water usage.
            </Box>
          </DialogContentText>
          <Box marginTop={3}>
            <TextField
              required
              margin="dense"
              id="orgName"
              name="orgName"
              label="Organization Name"
              fullWidth
              variant="filled"
              onChange={(evt) => setOrgName(evt.target.value)}
            />
          </Box>
          <Box width={'100%'} marginTop={2}>
            <SWLoadingButton variant="contained" color="info" fullWidth={true} onClick={handleSubmit} loading={loading}>
              Create Organization
            </SWLoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Home
