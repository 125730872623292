// @flow
/** @file React hook to work with remote Unit data (Wrapper for useStructure) */

import { useStructures } from '../Structures/useStructures'

import type { Structure } from '../Structures/types'
import type { ServerUnit } from './types'

// Util ///////////////////////////////////////////////////////////////////////

/**
 * Find Unit in array for Structures
 *
 * @param  {object[]} structures - Array of Structures
 * @param  {string} id - Unit ID
 * @returns {object} - {unit?: Unit, structure?: Structure}
 */
const getUnitInStructures = (
  structures: Structure[],
  id: string
): { unit?: ServerUnit, structure?: Structure } => {
  let unit;
  const structure = structures.find((structure) => {
    unit = structure.units.find((unit) => unit.id === id)
    return Boolean(unit)
  })

  return {
    unit,
    structure
  }
}

// Use Unit //////////////////////////////////////////////////////////////

/**
 * React hook to work with a single Unit
 *
 * @param {string} id - Unit ID
 * @returns {object} - Object with properties related to Unit data
 */
export const useUnit = (id: string) => {
  const { structures, error, mutate: mutateStructures } = useStructures()

  const { unit } = getUnitInStructures(structures || [], id)

  return {
    unit,
    isLoading: !error && !unit,
    error,
    mutate: mutateStructures
  }
}
