import * as React from 'react'
import { Link, useMatch } from 'react-router-dom'
import { IconButton, Collapse, List, ListItem, ListItemIcon, ListItemText, Tooltip, Divider } from '@mui/material'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { sortBy } from '../../util'
import { createHomeRoute, createStructureRoute, createUnitRoute, routes } from '../../app-routes'
import AddIcon from '@mui/icons-material/Add'
import BusinessIcon from '@mui/icons-material/Business'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { NewReleases as NewReleasesIcon } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { MiniDrawer, DrawerHeader } from './components'
import StructureForm from '../../Structures/Create'
import { getUnitStatus } from '../../Structures/util'
import { FormDialog as AddUnitDialog } from '../../Units/FormDialog'
import { create as createUnit } from '../../Units/api'
import { ErrorBoundary } from '../ErrorBoundary'
import { drawerWidth } from './util'
import { useStructures, useStructure } from '../../Structures/useStructures'
import { getUserId } from '../../auth'
import { version } from '../../config'
// Types
import type { Structure } from '../../Structures/types'
import type { Unit, AddUnitFormValues } from '../../Units/types'
import { StatusIcon } from '../StatusIcon'
import useMixPanel from '@/hooks/useMixPanel'

enum STATUS_COLORS {
  OKAY = '#00e676',
  WARNING = '#ffc400',
  ERROR = '#e53935'
}

// Constants //////////////////////////////////////////////////////////////////

const listItemStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none'
  },
  listItemText: {
    color: 'white'
  },
  listItemTextPrimary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4)
  }
}))

// Structure List Item ////////////////////////////////////////////////////////

type StructureListItemProps = {
  structure: Structure
  isSelected: boolean
  numberOfUnits: number
  onClick: () => void
}

const StructureListItem = ({ structure, isSelected, numberOfUnits, onClick }: StructureListItemProps) => {
  const classes = listItemStyles()

  return (
    <ErrorBoundary>
      <Link to={createStructureRoute(structure.id)} className={classes.link} onClick={onClick}>
        <ListItem button key={structure.name} selected={isSelected}>
          <ListItemIcon>
            <BusinessIcon htmlColor="white" />
          </ListItemIcon>
          <ListItemText
            primary={structure.name}
            secondary={`${numberOfUnits} Units`}
            classes={{
              root: classes.listItemText,
              primary: classes.listItemTextPrimary,
              secondary: classes.listItemText
            }}
          />
          {isSelected ? <ExpandLess htmlColor="white" /> : <ExpandMore htmlColor="white" />}
        </ListItem>
      </Link>
    </ErrorBoundary>
  )
}

// Unit List Item /////////////////////////////////////////////////////////////

type UnitListItemProps = {
  structureId: string
  structureName: string
  unit: Unit
  isSelected: boolean
  sidebarOpen: boolean
}

const UnitListItem = ({ structureId, structureName, unit, isSelected, sidebarOpen }: UnitListItemProps) => {
  const { trackEvent } = useMixPanel()
  const classes = listItemStyles()
  return (
    <ErrorBoundary>
      <Link
        to={createUnitRoute(structureId, unit.id)}
        className={classes.link}
        onClick={() => {
          trackEvent('Unit Viewed', {
            PropertyID: structureId,
            PropertyName: structureName,
            UnitID: unit.id,
            UnitName: unit.name
          })
        }}
      >
        <ListItem
          button
          dense
          key={unit.name}
          className={sidebarOpen ? classes.nestedListItem : ''}
          selected={isSelected}
        >
          <ListItemIcon>
            <MeetingRoomIcon htmlColor="white" />
          </ListItemIcon>
          <ListItemText
            primary={unit.name}
            classes={{
              root: classes.listItemText,
              primary: classes.listItemTextPrimary
            }}
          />
          {getUnitStatus(unit) !== 'NO_DEVICES' && <StatusIcon color={STATUS_COLORS[getUnitStatus(unit)]} />}
        </ListItem>
      </Link>
    </ErrorBoundary>
  )
}

// Component //////////////////////////////////////////////////////////////////

const useStyles: () => {
  bottomToolbarButtonContainer: string
  bottomToolbarGradient: string
  bottomToolbarRoot: string
  drawer: string
  homeListItem: string
  link: string
  list: string
  listItemText: string
  nestedListItem: string
} = makeStyles((theme) => ({
  bottomToolbarRoot: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0
  },
  bottomToolbarGradient: {
    backgroundImage: 'linear-gradient(to bottom, rgba(86, 86, 86, 0), rgba(86, 86, 86, 1))',
    height: theme.spacing(2),
    width: '100%'
  },
  bottomToolbarButtonContainer: {
    backgroundColor: 'rgba(86, 86, 86, 1)',
    width: '100%',
    height: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  drawer: {
    maxWidth: drawerWidth,
    minWidth: drawerWidth,
    backgroundColor: theme.palette.grey[800] //'rgb(86, 86, 86)'
  },
  homeListItem: {
    height: theme.spacing(8)
  },
  link: {
    textDecoration: 'none'
  },
  list: {
    paddingTop: 0
  },
  listItemText: {
    color: 'white'
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4)
  }
}))

interface DrawerProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export const Drawer = ({ open, setOpen }: DrawerProps) => {
  const classes = useStyles()
  const hMatch = useMatch(routes.home)
  const sMatch = useMatch(routes.property)
  const uMatch = useMatch(routes.unit)
  const structureId = sMatch?.params?.structureId || uMatch?.params?.structureId || ''
  const unitId = uMatch?.params?.unitId
  const { structures } = useStructures()
  const { structure, mutate: updateStructure } = useStructure(structureId)

  const [isExpanded, setIsExpanded] = React.useState(true)
  const [isAddStructureFormOpen, setIsAddStructureFormOpen] = React.useState(false)
  const [isAddUnitOpen, setIsAddUnitOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { trackEvent } = useMixPanel()

  const handleSubmitAddUnit = async ({ name, pipeDiameter, pipeMaterial }: AddUnitFormValues) => {
    if (!structure || !structureId) return
    setLoading(true)
    const newUnit = await createUnit(await getUserId(), structureId, structure.address, name, structure.units.length)
    await updateStructure({
      ...structure,
      units: [...structure.units, newUnit]
    })
    trackEvent('Unit Created', {
      PropertyName: structure.name,
      PropertyID: structure.id,
      UnitName: newUnit.name,
      UnitID: newUnit.id
    })
    setIsAddUnitOpen(false)
    setLoading(false)
  }

  return (
    <ErrorBoundary>
      <MiniDrawer anchor={'left'} variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon htmlColor="#fff" />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SimpleBar style={{ maxHeight: 'calc(100vh - 56px - 70px)' }}>
          <List className={classes.list}>
            <Link to={createHomeRoute()} className={classes.link}>
              <ListItem button selected={Boolean(hMatch)} className={classes.homeListItem}>
                <ListItemIcon>
                  <HomeWorkIcon htmlColor="white" />
                </ListItemIcon>
                <ListItemText primary={'Home'} className={classes.listItemText} />
              </ListItem>
            </Link>
            {structures
              ? structures.sort(sortBy('name')).map((s) => (
                  <React.Fragment key={s.id}>
                    <StructureListItem
                      structure={s}
                      numberOfUnits={s.units.length}
                      onClick={() => {
                        if (s.id === structureId) {
                          setIsExpanded(!isExpanded)
                        } else {
                          setIsExpanded(true)
                          trackEvent('Property Viewed', { PropertyID: s.id, PropertyName: s.name })
                        }
                      }}
                      isSelected={s.id === structureId}
                    />
                    <Collapse in={structureId === s.id && isExpanded}>
                      <List>
                        {(s.units || [])
                          .filter(Boolean)
                          .sort((a, b) => {
                            if (getUnitStatus(a) !== getUnitStatus(b)) return getUnitStatus(a) === 'ERROR' ? -1 : 1
                            return sortBy('name')(a, b)
                          })
                          .map((u) => {
                            return (
                              <UnitListItem
                                key={u.id}
                                structureId={s.id}
                                structureName={s.name}
                                unit={u}
                                isSelected={u.id === unitId}
                                sidebarOpen={open}
                              />
                            )
                          })}
                        <ListItem
                          button
                          onClick={() => setIsAddUnitOpen(true)}
                          className={open ? classes.nestedListItem : ''}
                        >
                          <ListItemIcon>
                            <AddIcon htmlColor="white" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Add Unit"
                            classes={{
                              root: classes.listItemText
                            }}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))
              : null}
            <ListItem button onClick={() => setIsAddStructureFormOpen(true)}>
              <ListItemIcon>
                <AddIcon htmlColor="white" />
              </ListItemIcon>
              <ListItemText primary="Add Property" className={classes.listItemText} />
            </ListItem>
          </List>
        </SimpleBar>
        <Tooltip sx={{ position: 'absolute', bottom: 20, left: 20 }} title={version}>
          <NewReleasesIcon htmlColor="white" />
        </Tooltip>
        <StructureForm open={isAddStructureFormOpen} onClose={() => setIsAddStructureFormOpen(false)} />
        <AddUnitDialog
          addOrEdit={'ADD'}
          name={`${(structure?.units.length || 0) + 1}`}
          pipeDiameter={1}
          pipeMaterial={'COPPER-M'}
          open={isAddUnitOpen}
          onClose={() => setIsAddUnitOpen(false)}
          onSubmit={handleSubmitAddUnit}
          loading={loading}
        />
      </MiniDrawer>
    </ErrorBoundary>
  )
}
