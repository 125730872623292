import { createTheme, adaptV4Theme } from '@mui/material/styles'
// See https://material-ui.com/customization/color/
// See https://cimdalli.github.io/mui-theme-generator/

export const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: '#4CEFDA' },
      secondary: { main: '#4790CC' },
      info: { main: '#047D95' },
      action: {
        disabledBackground: '#919293'
      }
    }
  })
)

export default theme
