import { Autocomplete, SxProps, TextField, Theme } from '@mui/material'
import { timeZones } from './timeZonesData'

interface IProps {
  value: string
  onChange: (value: string) => void
  sx?: SxProps<Theme>
}

const TimeZoneSelector = (props: IProps) => {
  return (
    <Autocomplete
      value={props.value}
      onChange={(event, newValue) => {
        const value = newValue ? newValue : props.value
        props.onChange(value)
      }}
      options={timeZones}
      renderInput={(params) => <TextField {...params} label="Time Zone" required />}
      sx={{ margin: '14px', marginTop: '10px', ...props.sx }}
    />
  )
}

export default TimeZoneSelector
