
/** Mapping of route strings */
export const routes = {
  home: '/',
  property: '/properties/:structureId',
  unit: '/properties/:structureId/units/:unitId'
}

export const createHomeRoute = (): string => '/'

export const createStructureRoute = (propertyId: string): string =>
  `/properties/${propertyId}`

export const createUnitRoute = (propertyId: string, unitId: string): string =>
  `${createStructureRoute(propertyId)}/units/${unitId}`

