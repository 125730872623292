/** @file Re-usable address section for Structure Form */

import makeStyles from '@mui/styles/makeStyles'
import { TextField } from '@mui/material'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import TimeZoneSelector from '@/components/TimeZoneSelector'

/**
 * Get correct event target
 *
 * @param  {Event} evt - Event
 * @returns {HTMLElement} - Resolved event target
 */
const getEventTarget = (evt: React.ChangeEvent<HTMLInputElement>) => evt.currentTarget || evt.target

/**
 * Classname/style generator
 */
const useStyles: () => { root: string; textField: string } = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  textField: {
    margin: theme.spacing(2)
  }
}))

type Props = {
  title?: string
  street?: string
  street2?: string
  city?: string
  state?: string
  zip?: string
  timeZone?: string
  onStreetChange: (street: string) => void
  onStreet2Change: (street2: string) => void
  onCityChange: (city: string) => void
  onStateChange: (state: string) => void
  onZipChange: (zip: string) => void
  onTimeZoneChange?: (value: string) => void
}

export const Address = (props: Props): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h6">{props.title || 'Enter your main address'}</Typography>
      <TextField
        className={classes.textField}
        label={'Street'}
        name={'street'}
        required={true}
        value={props.street}
        InputLabelProps={props.street ? { shrink: true } : {}}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          props.onStreetChange(getEventTarget(evt).value)
        }}
      />
      <TextField
        className={classes.textField}
        label={'Unit/Suite'}
        name={'street2'}
        helperText={'optional'}
        value={props.street2}
        InputLabelProps={props.street2 ? { shrink: true } : {}}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => props.onStreet2Change(getEventTarget(evt).value)}
      />
      <TextField
        className={classes.textField}
        label={'City'}
        name={'city'}
        required={true}
        value={props.city}
        InputLabelProps={props.city ? { shrink: true } : {}}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => props.onCityChange(getEventTarget(evt).value)}
      />
      <TextField
        className={classes.textField}
        label={'State'}
        name={'state'}
        required={true}
        value={props.state}
        InputLabelProps={props.state ? { shrink: true } : {}}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => props.onStateChange(getEventTarget(evt).value)}
      />
      <TextField
        className={classes.textField}
        label={'Zip'}
        name={'zip'}
        required={true}
        value={props.zip}
        InputLabelProps={props.zip ? { shrink: true } : {}}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          props.onZipChange(getEventTarget(evt).value)
        }}
      />
      {props.onTimeZoneChange !== undefined && (
        <TimeZoneSelector value={props.timeZone!} onChange={(i) => props.onTimeZoneChange!(i)} />
      )}
    </div>
  )
}
