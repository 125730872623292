// @flow
/** @file React hook to work with remote Bill data (Wrapper for useSWR) */
import useSWR from 'swr'
import { create as createBill, edit as editBill, getForStructure as getBilling, remove as removeBill, sendBills as sendBillsForBilling} from './api'
import { sortBy } from '../util'

import type { Bill } from './types'

// Util ///////////////////////////////////////////////////////////////////////

/** For request that should be cached for a day */
const STATIC_REQUEST_DEDUP_INTERVAL = 86400000 // Milliseconds in a day

// Use Bills /////////////////////////////////////////////////////////////

/**
 * React hook to work with remote Bill data
 *
 * @param {string} structureId - Structure ID
 * @returns {object} - Object with properties related to Bill data
 */
export const useBills = (
  structureId: string
): {
  bills: Bill[]
  create: (bill: any) => Promise<any>
  edit: (bill: any) => Promise<any>
  remove: (id: string) => Promise<any>
  sendBills: (id: string) => Promise<any>
  isLoading: boolean
  error: boolean
  mutate: (data: Bill[] | Promise<Bill[]>, shouldRevalidate?: boolean) => Promise<Bill[]>
} => {
  const {
    data: bills,
    error,
    mutate
  } = useSWR(
    () => (structureId ? `structures/${structureId}/bills` : null),
    () =>
      getBilling(structureId).then((bills) =>
        bills.sort(sortBy('startDate')).map((bill, idx) => ({ ...bill, name: `Bill #${idx + 1}` }))
      ),
    { dedupingInterval: STATIC_REQUEST_DEDUP_INTERVAL }
  )

  /**
   * Create Bill
   *
   * @param {object} bill - bill to create
   * @returns {Promise<void>} - Promise that resolves when the bill is created
   */
  const create = (bill: any) => {
    return createBill(bill).then((resp) => {
      mutate()
      return resp
    })
  }

  /**
   * Edit Bill
   *
   * @param {object} bill - New Bill data
   * @returns {Promise<void>} - Promise that resolves when the bill is edited
   */
  const edit = (bill: any) => {
    return editBill(bill).then(() => mutate())
  }

  /**
   * Delete Bill
   *
   * @param {string} id - ID of the bill to remove
   * @returns {Promise<void>} - Promise that resolves when the bill is removed
   */
  const remove = (id: string) => {
    return removeBill(id).then(() => mutate())
  }

  /**
   * Send Bills
   *
   * @param {string} id - ID of the bill to remove
   * @returns {Promise<void>} - Promise that resolves when the bills have been sent
   */
  const sendBills = (id: string) => {
    return sendBillsForBilling(id).then(() => mutate())
  }

  return {
    bills: bills || [],
    create,
    edit,
    remove,
    sendBills,
    isLoading: !error && !bills,
    error,
    // @ts-ignore
    mutate: (data, update) => {
      // @ts-ignore
      return mutate(data, update)
    }
  }
}
