/** @file Buttons for the Structure Form */

import { Button } from '../../components/Button'
import { ChevronRight } from '@mui/icons-material'
import React from 'react'
import { SWLoadingButton } from '@/components/LoadingButton'

type ButtonsProps = {
  onPrev: () => void
  onNext: () => void
  prevLabel: string
  nextLabel: string
  isNextDisabled: boolean
  showNextChevron: boolean
  createLoading: boolean
}

export const Buttons = ({
  onPrev,
  onNext,
  prevLabel,
  nextLabel,
  isNextDisabled,
  showNextChevron = true,
  createLoading
}: ButtonsProps): JSX.Element => {
  return (
    <React.Fragment>
      {prevLabel ? (
        <Button name={'prev'} onClick={onPrev} color='secondary'>
          {prevLabel}
        </Button>
      ) : null}

      <SWLoadingButton
        name={'next'}
        disabled={isNextDisabled}
        onClick={onNext}
        loading={createLoading}
      >
        {nextLabel}
        {showNextChevron ? <ChevronRight /> : null}
      </SWLoadingButton>
    </React.Fragment>
  )
}
