/** @file React hook to work with remote Structure data (Wrapper for useSWR) */

import useSWR from 'swr'
import { list } from './api'

import type { Structure } from './types'

// Util ///////////////////////////////////////////////////////////////////////

/** For request that should be cached for a day */
const STATIC_REQUEST_DEDUP_INTERVAL = 86400000 // Milliseconds in a day

const getStructureInStructures = (structures: Structure[], id: string): Structure | null => {
  const structure = structures.find((s) => s.id === id)

  return structure || null
}

/**
 * Merge updates to Structure into overall Structures
 *
 * @param  {object} newStructure   - New Structure data
 * @param  {object} [oldStructure] - Old Structure data
 * @param  {object[]} structures   - Array of Structures
 * @returns {object} - {mergedStructure: Structure, mergedStructures: Structure[]}
 */
const mergeStructure = (newStructure: Structure, oldStructure: Structure | null, structures?: Structure[]) => {
  const mergedStructure: Structure = { ...oldStructure, ...newStructure }
  const mergedStructures = (structures || []).map((s: Structure) => (s.id === mergedStructure.id ? mergedStructure : s))

  return { mergedStructure, mergedStructures }
}

interface UseStructurePayload {
  structures?: Structure[]
  isLoading: boolean
  error: boolean
  mutate: (data?: Structure[] | Promise<Structure[]>, shouldRevalidate?: boolean) => Promise<Structure[] | undefined>
}

export const useStructures = (): UseStructurePayload => {
  const {
    data: structures,
    error,
    mutate
  } = useSWR(`/structures`, list, {
    dedupingInterval: STATIC_REQUEST_DEDUP_INTERVAL
  })

  return {
    structures: structures,
    isLoading: !error && !structures,
    error,
    mutate
  }
}

// Use Structure //////////////////////////////////////////////////////////////

/**
 * React hook to work with a single Structure
 *
 * @param {string} id - Structure ID
 * @returns {object} - Object with properties related to Structure data
 */
export const useStructure = (id: string) => {
  const { structures = [], error, mutate: mutateStructures } = useStructures()

  const structure = getStructureInStructures(structures, id)

  const mutate = (data?: Structure | Promise<{ structure: Structure }>, shouldFetchNew: boolean = true) => {
    // If `data` is a promise, wait for it to resolve then use the value `data` resolves to
    if (!!data) {
      if (data instanceof Promise) {
        data.then(({ structure }) => {
          const { mergedStructures } = mergeStructure(structure, null, structures || [])

          return mutateStructures(mergedStructures, shouldFetchNew).then((structures = []) =>
            getStructureInStructures(structures, id)
          )
        })
      }
      // Else if `data` is an object, just use `data`
      else if (data instanceof Object) {
        const { mergedStructures } = mergeStructure(data, structure, structures || [])

        return mutateStructures(mergedStructures, shouldFetchNew).then((structures = []) =>
          getStructureInStructures(structures, id)
        )
      } else {
        return mutateStructures().then((structures = []) => getStructureInStructures(structures, id))
      }
      // Else just call `mutateStructures` without any arguements
    } else {
      return mutateStructures().then((structures = []) => getStructureInStructures(structures, id))
    }
  }

  return {
    structure,
    isLoading: !error && !structures,
    error,
    mutate
  }
}
