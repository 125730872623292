import { Button, Typography, Tooltip, Box } from '@mui/material'
import { PropertyCard } from './StatusCard.styles'
import type { DeviceStatus } from '../../types'
import { StatusIcon } from '../StatusIcon'

interface StatusCardProps {
  link: string
  title: string
  subtitle: string
  status: DeviceStatus
  icon: React.ReactNode
  errorMessage: string[]
}

enum STATUS_COLORS {
  OKAY = '#00e676',
  WARNING = '#ffc400',
  ERROR = '#e53935'
}

const StatusCard = ({ link, title, subtitle, status, icon, errorMessage }: StatusCardProps): JSX.Element => {
  const errorMsg = errorMessage.map((str, idx) => (
    <Typography variant="body2" key={idx}>
      {str}
    </Typography>
  ))

  return (
    <PropertyCard to={link}>
      <Button
        variant="text"
        sx={{
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 2,
          boxShadow: 3,
          position: 'relative',
          width: 250,
          color: '#000',
          height: '200px'
        }}
      >
        <div>
          {icon}
          <Typography variant="h6">{title}</Typography>
          <Typography variant="subtitle1">{subtitle}</Typography>
          {status !== 'NO_DEVICES' && (
            <Tooltip title={errorMsg} disableHoverListener={status === 'OKAY'}>
              <Typography variant="caption">{status}</Typography>
            </Tooltip>
          )}
          {status !== 'NO_DEVICES' && (
            <Box position="absolute" top="12px" right="12px">
              <StatusIcon color={STATUS_COLORS[status]} />
            </Box>
          )}
        </div>
      </Button>
    </PropertyCard>
  )
}

export default StatusCard
