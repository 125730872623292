import { addCreate } from '../util'
import { format, parseISO } from 'date-fns'
import type { UnitUsage } from '../Usage/types'

type CSVMetadata = { start: number; end: number; timezone: string }

export const generateUsageCSV = (rawUsage: UnitUsage | undefined, metadata: CSVMetadata): string => {
  if (!rawUsage) {
    return ''
  }

  const usageByDate = {
    TOTAL: {},
    ...rawUsage?.devices.reduce((acc, cur) => ({ ...acc, [cur.name || cur.id]: {} }), {})
  }

  rawUsage?.devices.forEach((device) => {
    device.daily_usages.forEach((usageData) => {
      addCreate(usageByDate[device.name || device.id], usageData.date, Math.round(usageData.volume))
      addCreate(usageByDate.TOTAL, usageData.date, Math.round(usageData.volume))
    })
  })

  const headers = `Date (${metadata.timezone}),Unit Total (gal),"${Object.keys(usageByDate)
    .filter((k) => k !== 'TOTAL')
    .sort()
    .join(' (gal)","')} (gal)",,start: ${metadata.start},end: ${metadata.end},timezone: ${metadata.timezone}\n`

  const anyDates = Object.keys(usageByDate.TOTAL).length

  const totalsRow =
    anyDates &&
    `Total ${format(parseISO(Object.keys(usageByDate.TOTAL)[0]), 'M/d/yyyy')} - ${format(
      // @ts-ignore - toReversed() not recognized
      parseISO(Object.keys(usageByDate.TOTAL).toReversed()[0]),
      'M/d/yyyy'
      // @ts-ignore
    )},${Object.values(usageByDate.TOTAL).reduce((acc: number, cur: number) => acc + cur, 0)},${Object.keys(usageByDate)
      .filter((deviceId) => deviceId !== 'TOTAL')
      .sort()
      .map((deviceId) =>
        // @ts-ignore
        Object.values(usageByDate[deviceId])
          // @ts-ignore
          .reduce((acc, cur) => acc + cur)
      )
      .join(',')}\n`

  const body = Object.keys(usageByDate.TOTAL)
    .sort()
    .map((dateStr) => {
      return `${format(parseISO(dateStr), 'M/d/yyyy')},${usageByDate.TOTAL[dateStr]},${Object.keys(usageByDate)
        .filter((k) => k !== 'TOTAL')
        .sort()
        .map((k) => (!isNaN(usageByDate[k][dateStr]) ? usageByDate[k][dateStr] : 'Unknown'))
        .join(',')}`
    })
    .join('\n')

  return `${headers}${totalsRow || ''}${body}`
}
