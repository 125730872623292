import { createDefaultHeaders } from '../api'
import { getSession, urlWithAdminQuery } from '../auth'
import { OutageStatusResponse } from './types'

/**
 * Get outage status from backend
 *
 * @returns {Promise} - Result of call to get outage status
 */
export const defaultOutageStatusResponse = {
  is_elasticsearch_outage: false
} as OutageStatusResponse

export const getOutageStatus = (): Promise<OutageStatusResponse> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/outage`, session.adminEmail), {
      method: 'GET',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then((resp) => resp.json())
      // Don't break anything if the outage api fails, return default with no outages
      .catch((err) => {
        console.error(err)
        return defaultOutageStatusResponse
      })
  )
}
