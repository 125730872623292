import React from 'react';
import { Dialog } from '@mui/material';

const CustomDialog = ({ mouseX, mouseY, open, onClose, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: "transparent"
      }}
      PaperProps={{
        style: {
          position: 'absolute',
          top: mouseY,
          left: mouseX,
        },
      }}
      BackdropProps={{
        invisible: true, // Oculta el backdrop
      }}
    >
      {children}
    </Dialog>
  );
};

export default CustomDialog;
