/** @file Create structure units section for Structure Form */

import { TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import * as React from 'react'
import { sortByIndex } from '../../util'

import type { Unit } from '../../../Units/types'

type UnitCardProps = {
  className: string
  name?: string
  onNameChange: (name: string) => void
  isValid: boolean
}

/**
 * Unit Card
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Unit Card
 */
const UnitCard = (props: UnitCardProps) => {
  return (
    <TextField
      className={props.className}
      label={'Unit Name'}
      name={'id'}
      required={true}
      value={props.name}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
        props.onNameChange(getEventTarget(evt).value)
      }}
    />
  )
}

/**
 * Get correct event target
 *
 * @param  {Event} evt - Event
 * @returns {HTMLElement} - Resolved event target
 */
const getEventTarget = (evt) => evt.currentTarget || evt.target

/**
 * Classname/style generator
 */
const useStyles: () => {
  root: string
  input: string
  sameRowInputContainer: string
  cardInput: string
  cardInputContainer: string
  textHeader: string
} = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    margin: theme.spacing(1)
  },
  sameRowInputContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  cardInput: {
    margin: theme.spacing(1),
    display: 'flex',
    marginTop: '15px'
  },
  textHeader: {
    textAlign: 'center',
    marginTop: '10px'
  },
  cardInputContainer: {
    padding: '15px'
  }
}))

type Props = {
  onNameChange: (id: string, name: string) => void
  units: Unit[]
  unitErrorObj: { [id: string]: boolean }
}

export const CreateUnits = (props: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.textHeader}>
        <Typography variant="h4">Unit Details</Typography>
        <Typography variant="subtitle1">Please provide names for each unit.</Typography>
      </div>
      <div className={classes.cardInputContainer}>
        {props.units.sort(sortByIndex).map((unit) => (
          <UnitCard
            className={classes.cardInput}
            isValid={!props.unitErrorObj[unit.id]}
            key={unit.id}
            name={unit.name}
            onNameChange={(newName) => props.onNameChange(unit.id, newName)}
          />
        ))}
      </div>
    </div>
  )
}
