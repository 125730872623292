import { urlWithAdminQuery } from '@/auth'
import { IReport } from './types'
import { throwIfError } from '@/api'
import { toast } from 'react-toastify'
import { log } from '@/util'
import { v4 as uuid } from 'uuid'

/**
 * Get Unit Usage Data Info from backend
 *
 * @returns {Promise} - Result of call to get user info
 */
export const getUnitUsageData = (encryptedData: string): Promise<IReport> => {
  return (
    fetch(urlWithAdminQuery(`/report/decryptUnitUsageData`, ''), {
      method: 'POST',
      body: JSON.stringify({
        encrypted_data: `${encryptedData}`
      }),
      headers: new Headers({
        'X-Api-Key': `${process.env.REACT_APP_API_TOKEN}`,
        'Content-Type': 'application/json',
        'request-id': uuid()
      })
    })
      .then(throwIfError)
      // Get JSON
      .then((resp) => resp.json())
      .catch((err) => {
        const newErr = new Error(`Error getting Unit Usage data [Error: ${err.body}]`)
        toast.error(newErr.message)
        log.error(newErr)
        throw newErr
      })
  )
}
