export const StatusIcon = ({ color }) => {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 250 250"
      enableBackground="new 0 0 250 250;"
      width="22px"
      height="22px"
      style={{ transform: 'rotate(90deg)' }}
    >
      <path
        fill={color}
        d="M198.3,225c-1.5,0-3-0.7-4-1.9c-1.7-2.2-1.3-5.3,0.9-7c28.5-22,44.8-55.2,44.8-91s-16.3-69.1-44.8-91
c-2.2-1.7-2.6-4.8-0.9-7c1.7-2.2,4.8-2.6,7-0.9c30.9,23.9,48.7,60,48.7,99c0,39-17.7,75.1-48.7,99C200.4,224.7,199.3,225,198.3,225z
"
      />
      <path fill="none" d="M198.3,220c28.4-21.9,46.7-56.3,46.7-95s-18.3-73.1-46.7-95" />
      <path
        fill={color}
        d="M174.2,199.2c-1.6,0-3.1-0.7-4.1-2.1c-1.6-2.3-1.1-5.4,1.2-7c21.1-15,33.7-39.4,33.7-65.2
c0-25.8-12.6-50.1-33.7-65.2c-2.3-1.6-2.8-4.7-1.2-7c1.6-2.3,4.7-2.8,7-1.2C200.8,68.6,215,96,215,125s-14.2,56.4-37.9,73.3
C176.2,198.9,175.2,199.2,174.2,199.2z"
      />
      <path fill="none" d="M174.2,194.2c21.6-15.4,35.8-40.6,35.8-69.2s-14.2-53.8-35.8-69.2" />
      <path
        fill={color}
        d="M150,173.3c-1.7,0-3.4-0.9-4.3-2.5c-1.4-2.4-0.6-5.4,1.8-6.8c13.9-8,22.5-23,22.5-39c0-16-8.6-30.9-22.5-39
c-2.4-1.4-3.2-4.4-1.8-6.8c1.4-2.4,4.4-3.2,6.8-1.8c17,9.8,27.5,28.1,27.5,47.6c0,19.5-10.6,37.8-27.5,47.6
C151.7,173.1,150.8,173.3,150,173.3z"
      />
      <path fill="none" d="M150,81.7c15,8.6,25,24.8,25,43.3s-10.1,34.6-25,43.3" />
      <path
        fill={color}
        d="M51.7,225c-1.1,0-2.1-0.3-3.1-1C17.7,200.1,0,164,0,125c0-39,17.7-75.1,48.7-99c2.2-1.7,5.3-1.3,7,0.9
c1.7,2.2,1.3,5.3-0.9,7C26.3,55.9,10,89.1,10,125s16.3,69.1,44.8,91c2.2,1.7,2.6,4.8,0.9,7C54.7,224.3,53.2,225,51.7,225z"
      />
      <path fill="none" d="M51.7,220C23.3,198.1,5,163.7,5,125s18.3-73.1,46.7-95" />
      <path
        fill={color}
        d="M75.8,199.2c-1,0-2-0.3-2.9-0.9C49.2,181.4,35,154,35,125s14.2-56.4,37.9-73.3c2.3-1.6,5.4-1.1,7,1.2
c1.6,2.3,1.1,5.4-1.2,7C57.6,74.9,45,99.2,45,125c0,25.8,12.6,50.1,33.7,65.2c2.2,1.6,2.8,4.7,1.2,7
C78.9,198.5,77.4,199.2,75.8,199.2z"
      />
      <path fill="none" d="M75.8,55.8C54.2,71.2,40,96.4,40,125s14.2,53.8,35.8,69.2" />
      <path
        fill={color}
        d="M100,173.3c-0.8,0-1.7-0.2-2.5-0.7C80.6,162.8,70,144.5,70,125c0-19.5,10.6-37.8,27.5-47.6
c2.4-1.4,5.4-0.6,6.8,1.8c1.4,2.4,0.6,5.4-1.8,6.8c-13.9,8-22.5,23-22.5,39c0,16,8.6,30.9,22.5,39c2.4,1.4,3.2,4.4,1.8,6.8
C103.4,172.4,101.8,173.3,100,173.3z"
      />
      <path fill="none" d="M100,81.7c-15,8.6-25,24.8-25,43.3s10.1,34.6,25,43.3" />
    </svg>
  )
}
