import { ServerDevice } from '@/Devices/types'
import { Box, InputAdornment, TextField, styled} from '@mui/material'
import { DEVICE_SETTINGS_CONSTANTS, SECONDS_CONSTANT } from '../DeviceStatusTable'

type props = {
  device: ServerDevice | undefined
  updateDeviceData: (value: boolean | string | number, key: string) => void
}

const TextCustom = styled('p')({
  textAlign: 'center'
})

export const HighFlowLeakAlert = ({ device, updateDeviceData }: props) => {
  var desired_usage_alert_window_hours: number | undefined = Math.round(parseInt(String(device?.desired_usage_alert_window_seconds)) / SECONDS_CONSTANT.SECONDS_IN_AN_HOUR)
  if (Number.isNaN(desired_usage_alert_window_hours)) {
    desired_usage_alert_window_hours = device?.desired_usage_alert_window_seconds
  }

  return (
    <Box>
      <TextCustom>
        Notify me if more than <b>{device?.desired_usage_alert_threshold_gallons} gallons</b> of water is used in under{' '}
        <b>{desired_usage_alert_window_hours} hours</b>
      </TextCustom>
      <TextCustom>
        You will be notified every{' '}
        <b>
          {((device?.desired_usage_alert_disable_seconds || 0) +
            (Number(device?.desired_usage_alert_window_seconds) || 0)) /
            3600}{' '}
          hours
        </b>{' '}
        as long as this condition exists.
      </TextCustom>
      <Box mt="25px" display="flex" justifyContent="center" alignItems="center">
        <TextField
          id="outlined-select-currency"
          label="Usage Above"
          type="number"
          variant="standard"
          value={device?.desired_usage_alert_threshold_gallons}
          sx={{ width: 180 }}
          onChange={(value) => updateDeviceData(value.target.value, 'desired_usage_alert_threshold_gallons')}
          InputProps={{
            endAdornment: <InputAdornment position="end">gal</InputAdornment>,
            inputProps: {
              min: DEVICE_SETTINGS_CONSTANTS.ALERT_THR_GALL_MIN 
            }
          }}
        />
      </Box>
      <Box mt="25px" display="flex" justifyContent="center" alignItems="center">
        <TextField
          id="outlined-select-currency"
          label="Usage Window"
          type="number"
          variant="standard"
          value={desired_usage_alert_window_hours}
          sx={{ width: 180 }}
          onChange={(value) => {
            const seconds = parseInt(value.target.value) * SECONDS_CONSTANT.SECONDS_IN_AN_HOUR
            const secondsString = Number.isNaN(seconds) ? value.target.value : String(seconds)
            updateDeviceData(secondsString, 'desired_usage_alert_window_seconds')
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">hours</InputAdornment>,
            inputProps: { 
              max: DEVICE_SETTINGS_CONSTANTS.ALERT_WINDOW_HOUR_MAX, 
              min: DEVICE_SETTINGS_CONSTANTS.ALERT_WINDOW_HOUR_MIN 
            }
          }}
        />
      </Box>
    </Box>
  )
}