import * as React from 'react'
import { useLocalStorage } from 'react-use'

interface AdminAccessContextValues {
  isAdminLogin: boolean
  adminEmail?: string
  updateAdminInfo: (value: any) => void
  deleteAdminInfo: () => void
}

const initialValues = {
  isAdminLogin: false,
  adminEmail: ''
} as AdminAccessContextValues

const AdminAccessContext = React.createContext<AdminAccessContextValues>(initialValues)

export const AdminAccessProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [adminInfo, updateAdminInfo, deleteAdminInfo] = useLocalStorage('adminInfo', initialValues)
  const { isAdminLogin = false, adminEmail } = adminInfo || {}

  return (
    <AdminAccessContext.Provider
      value={{ isAdminLogin, adminEmail, updateAdminInfo, deleteAdminInfo }}
    >
      {children}
    </AdminAccessContext.Provider>
  )
}

export const useAdminAccess = (): AdminAccessContextValues => React.useContext(AdminAccessContext)
