import { createDefaultHeaders } from '../api'
import { getSession, urlWithAdminQuery } from '../auth'
import { toast } from 'react-toastify'
import { ServerDevice } from './types'

// const API_URL = process.env.REACT_APP_API_URL

export const edit = (editObj: ServerDevice) => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/device/${editObj.id}`, session.adminEmail), {
      method: 'PUT',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      }),
      body: JSON.stringify({
        ix: editObj.ix,
        name: editObj.name,
        leak_enabled: editObj.leak_enabled,
        desired_usage_alert_enabled: editObj.desired_usage_alert_enabled,
        leak_threshold_hours: Number(editObj.leak_threshold_hours),
        desired_usage_alert_threshold_gallons: Number(editObj.desired_usage_alert_threshold_gallons),
        desired_usage_alert_window_seconds: Number(editObj.desired_usage_alert_window_seconds),
      })
    })
      .then((resp) => {
        return resp.json()
      })
      .catch((err) => {
        console.error(err)
        toast.error(`Error: Editing Device for device Id: ${editObj.id}`)
        return err
      })
  )
}

/** Update multiple devices at once */
export const batchEdit = (
  deviceIds: string[],
  leakProperties: {
    leak_enabled?: boolean
    leak_threshold_hours?: number
    leak_flow_threshold_gallons?: number
    desired_usage_alert_window_seconds?: number,
    desired_usage_alert_threshold_gallons?: number
  }
): Promise<ServerDevice[]> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/device/batchUpdate`, session.adminEmail), {
      method: 'PUT',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      }),
      body: JSON.stringify({
        device_ids: deviceIds,
        ...leakProperties
      })
    })
      .then((resp) => {
        return resp.json()
      })
      .catch((err) => {
        console.error(err)
        toast.error(`Error: Updating multiple devices`)
        return err
      })
  )
}
