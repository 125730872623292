import { Stack, Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IReport } from './types'
import { getUnitUsageData } from './api'
import { Chart } from '@/components/Chart'
import { Data } from '@/components/Chart/types'
import generatePDF, { Resolution, Margin, Options } from 'react-to-pdf'
import InitialLoader from '@/components/Loader/InitialLoader'
import { Button } from '@/components/Button'

const ReportPage = () => {
  const [searchParams] = useSearchParams()
  const [encryptedData] = useState(searchParams.get('d'))
  const [reportData, setReportData] = useState<IReport>()
  const [data, setData] = useState<Data>()
  const [generatingPDF, setGeneratingPDF] = useState(false) // State to track PDF generation
  const options: Options = {
    method: 'save',
    resolution: Resolution.HIGH,
    page: {
      margin: Margin.MEDIUM,
      format: 'a4',
      orientation: 'portrait'
    },
    overrides: {
      pdf: {
        unit: 'pt',
        format: 'a4',
        orientation: 'portrait'
      }
    }
  }

  const targetRef = useRef()

  useEffect(() => {
    const getData = async () => {
      const base64Str: string = decodeURIComponent(encryptedData || '')
      const data = await getUnitUsageData(base64Str)
      setReportData(data)
    }
    if (encryptedData !== null && reportData === undefined) getData()
  }, [encryptedData, reportData])

  const formatDate = (epochSeconds: number, timeZone: string) =>
    new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: timeZone
    }).format(new Date(epochSeconds * 1000))

  useEffect(() => {
    if (reportData) {
      const { usage_daily_volumes, usage_start_epoch_seconds, usage_end_epoch_seconds, timezone_key } =
        reportData.unit_usage_report

      const generateDateRange = (startEpoch: number, endEpoch: number, timeZone: string): string[] => {
        const dates: string[] = []
        let currentDate = new Date(startEpoch * 1000)

        while (currentDate.getTime() <= endEpoch * 1000) {
          dates.push(currentDate.toISOString().slice(0, 10))
          currentDate.setDate(currentDate.getDate() + 1)
        }

        return dates
      }

      const dateArray: string[] = generateDateRange(usage_start_epoch_seconds, usage_end_epoch_seconds, timezone_key)

      const usageData: Data = dateArray
        .map((date, index) => ({
          date: date,
          volume: usage_daily_volumes[index],
          gallons: usage_daily_volumes[index],
          label: date
        }))
        .filter((entry) => entry.volume !== undefined && entry.gallons !== undefined)

      const filteredData = usageData.filter((entry) => entry.volume !== undefined && entry.gallons !== undefined)

      setData(filteredData)
    }
  }, [reportData])

  const handleGeneratePDF = async () => {
    await setGeneratingPDF(true)
    await generatePDF(targetRef, options)
    setGeneratingPDF(false)
  }

  return (
    <Box ref={targetRef} sx={{ typography: 'body1', padding: '16px', display: 'inline-block' }}>
      <Stack direction={'row'} spacing={5}>
        <Box sx={{ fontSize: '36px', fontWeight: 'bold' }}>Tenant Usage Report</Box>
        {!generatingPDF && ( // Render the button conditionally based on generatingPDF state
          <Button color="secondary" onClick={() => handleGeneratePDF()}>
            Download Report
          </Button>
        )}
      </Stack>
      {!reportData ? (
        <InitialLoader />
      ) : (
        <Box paddingTop={5}>
          <Stack direction={'column'} spacing={10}>
            <Box>
              <Box sx={{ fontSize: '20px', fontWeight: 'bold' }}>Unit Details</Box>
              <Stack spacing={1} paddingTop={2}>
                <Stack direction={'row'} spacing={1}>
                  <Box>Property Address: </Box>
                  <Box sx={{ fontWeight: 'bold' }}>{reportData.unit_usage_report.property_name}</Box>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <Box>Unit Name: </Box>
                  <Box sx={{ fontWeight: 'bold' }}>{reportData.unit_usage_report.unit_name}</Box>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <Box>Contact Name: </Box>
                  <Box sx={{ fontWeight: 'bold' }}>{reportData.unit_usage_report.subscriber_first_name}</Box>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <Box>Contact Email: </Box>
                  <Box sx={{ fontWeight: 'bold' }}>{reportData.unit_usage_report.subscriber_email}</Box>
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Box sx={{ fontSize: '20px', fontWeight: 'bold' }}>Usage Details</Box>
              <Stack spacing={1} paddingTop={2}>
                <Stack direction={'row'} spacing={1}>
                  <Box>Usage Period: </Box>
                  <Box sx={{ fontWeight: 'bold' }}>
                    {formatDate(
                      reportData.unit_usage_report.usage_start_epoch_seconds,
                      reportData.unit_usage_report.timezone_key
                    )}{' '}
                    -{' '}
                    {formatDate(
                      reportData.unit_usage_report.usage_end_epoch_seconds,
                      reportData.unit_usage_report.timezone_key
                    )}{' '}
                    {reportData.unit_usage_report.timezone_key}
                  </Box>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <Box>Water Usage: </Box>
                  <Box sx={{ fontWeight: 'bold' }}>
                    {reportData.unit_usage_report.usage_total_volume} {reportData.unit_usage_report.usage_volume_units}
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          {reportData && data && (
            <Chart
              data={data}
              x="date"
              y="volume"
              title="Daily Water Usage (gallons)"
              style={{ maring: '0px !important', marginTop: '20px', width: '100%' }}
              yAxisType=""
            />
          )}
        </Box>
      )}
    </Box>
  )
}

export default ReportPage
