// @flow

/** @file Utilities for chart */

import { dateStringToDate, formatNumber } from '../../util'
import { format } from 'date-fns'

import type { DateStr } from '../../types'

/**
 * Create label for individual chart data
 *
 * @param {number} gallons - Gallons
 * @param {string} dateStr - Date string (YYYY-MM-DD)
 * @param {[number]}cost   - Cost of gallons
 * @returns {string} - Label string
 */
export const createLabel = (
  gallons: number,
  dateStr: DateStr,
  cost?: number
): string =>
  `${formatNumber(gallons)} gallons ${
    cost ? `($${formatNumber(cost, 2)}) ` : ''
  }used on ${format(dateStringToDate(dateStr), 'MMM do')}`
