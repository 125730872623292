/** @file Label/Value component */

import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react'
import classNames from 'classnames'
import Typography from '@mui/material/Typography'

const labelValueClasses: () => {
  container: string,
  label: string
} = makeStyles((theme) => ({
  container: {
    alignItems: 'baseline',
    display: 'flex'
  },
  label: {
    marginRight: theme.spacing(1)
  }
}))

type LabelValueProps = {
  containerClass?: string,
  label?: string,
  labelClass?: string,
  onClick?: () => any,
  value?: string | number | React.ReactNode,
  valueClass?: string
}

/**
 * Label/Value component (just typography/layout)
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Label/Value text
 */
export const LabelValue = (props: LabelValueProps) => {
  const classes = labelValueClasses()

  return (
    <div
      className={classNames(classes.container, props.containerClass)}
      onClick={props.onClick}
    >
      {props.label ? (
        <Typography
          variant={'h6'}
          className={classNames(classes.label, props.labelClass)}
        >{`${props.label || ''}: `}</Typography>
      ) : null}
      <Typography variant={'body1'} className={props.valueClass}>
        {props.value}
      </Typography>
    </div>
  )
}
