import type { Structure } from '../types'
import type { Address } from '../../types'

const address = {
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: ''
}

export enum actions {
  SET_MAILING_ADDRESS = 'SET_MAILING_ADDRESS',
  CREATE_STRUCTURE = 'CREATE_STRUCTURE',
  SET_UNIT = 'SET_UNIT',
  SET_UNITS = 'SET_UNITS',
  RESET_STRUCTURE = 'RESET_STRUCTURE'
}

type State = {
  mailingAddress: Address
  structures: { [x: string]: Structure }
}

export const initialValue: State = {
  mailingAddress: {
    ...address
  },
  structures: {}
}

/**
 * Reducer for structure form
 *
 * @param  {object} state - Current state
 * @param  {object} action - Action to alter state
 * @returns {object} - New state
 */
export const reducer = (state: State, action: { type: keyof typeof actions; payload: any }) => {
  switch (action.type) {
    case actions.SET_MAILING_ADDRESS:
      return { ...state, mailingAddress: action.payload }

    case actions.CREATE_STRUCTURE:
      return {
        ...state,
        structures: { ...state.structures, [action.payload.id]: action.payload }
      }

    case actions.SET_UNITS:
      return {
        ...state,
        structures: {
          ...state.structures,
          [action.payload.structureId]: {
            ...state.structures[action.payload.structureId],
            units: action.payload.units
          }
        }
      }

    case actions.SET_UNIT:
      return {
        ...state,
        structures: {
          ...state.structures,
          [action.payload.structureId]: {
            ...state.structures[action.payload.structureId],
            units: state.structures[action.payload.structureId].units.map((unit) => {
              if (unit.id === action.payload.unitId) {
                return action.payload.unitData
              } else {
                return unit
              }
            })
          }
        }
      }

    case actions.RESET_STRUCTURE:
      return { ...initialValue }

    default:
      throw new Error('UNKNOWN_ACTION_TYPE')
  }
}
