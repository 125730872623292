import { Box, IconButton, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Check as CheckIcon, Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  button: {
    height: 'fit-content'
  },
  editIcon: {
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  inputRoot: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: '1.167'
  }
}))

interface NameProps {
  value: string
  onUpdate: (value: string) => void
}

export const EditableText = ({ value, onUpdate }: NameProps): JSX.Element => {
  const classes = useStyles()
  const [textValue, setTextValue] = useState(value)
  const [isEditing, setIsEditing] = useState(false)

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!isEditing ? (
        <>
          <Box sx={{ fontSize: '24px' }}>{textValue}</Box>
          <IconButton onClick={() => setIsEditing(true)} className={classes.editIcon} size="large">
            <EditIcon />
          </IconButton>
        </>
      ) : (
        <>
          <TextField
            fullWidth
            value={textValue}
            onChange={(evt) => {
              setTextValue(evt.target.value)
            }}
            margin="dense"
            inputProps={{
              className: classes.inputRoot
            }}
          />
          <IconButton
            onClick={() => {
              setIsEditing(false)
              onUpdate(textValue)
            }}
            className={classes.button}
            size="large"
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setTextValue(value)
              setIsEditing(false)
            }}
            className={classes.button}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </>
      )}
    </div>
  )
}
