import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import styled from '@emotion/styled'

const OrderIconContainer = styled('div')`
  display: grid;
  grap: 0;
`
type props = {
  type: string
}

export const OrderIcon = ({ type }: props) => {
  return (
    <OrderIconContainer>
      <ArrowDropUpIcon sx={{ color: type === 'asc' ? 'black' : '#969696', position: 'relative', bottom: '-8px' }} />
      <ArrowDropDownIcon sx={{ color: type === 'desc' ? 'black' : '#969696', position: 'relative', top: '-8px' }} />
    </OrderIconContainer>
  )
}
