/** @file Welcome section for Structure Form */
import Typography from '@mui/material/Typography'

export const Welcome = (): JSX.Element => (
  <div>
    <Typography>Let's add a new property to your account. Here's what you'll do.</Typography>
    <ol>
      <li>
        <b>Enter property details:</b> Provide information about the property you're submetering.
      </li>
      <li>
        <b>Add unit information:</b> Enter details for each individual unit you want to submeter within the property.
      </li>
      <li>
        <b>Review and confirm:</b> Take a moment to review your entries before finalizing the setup.
      </li>
    </ol>
    <Typography>
      Make sure the property receives a single water bill from one utility provider. If you receive multiple water
      bills, separate them into their own properties.
    </Typography>
    <br />
    <Typography>Ready to begin? Don't worry, you can always make changes later.</Typography>
  </div>
)
