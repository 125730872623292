/** @file Dialog to add a single unit */

import makeStyles from '@mui/styles/makeStyles'
import { TextField } from '@mui/material'
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import type { PipeDiameter, PipeMaterial } from '../types'
import { SWLoadingButton } from '@/components/LoadingButton'

type FormProps = {
  className: string
  name?: string
  onNameChange: (name: string) => void
  onPipeSizeChange: (size: PipeDiameter) => void
  onPipeTypeChange: (type: PipeMaterial) => void
  pipeSize: PipeDiameter
  pipeMaterial: PipeMaterial
}

const Form = (props: FormProps) => {
  return (
    <React.Fragment>
      {props.name !== undefined && (
        <TextField
          className={props.className}
          label={'Unit ID'}
          name={'id'}
          helperText={'eg: "1", "205-b"'}
          required={true}
          value={props.name}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            props.onNameChange(getEventTarget(evt).value)
          }}
        />
      )}
    </React.Fragment>
  )
}

/**
 * Get correct event target
 *
 * @param  {Event} evt - Event
 * @returns {HTMLElement} - Resolved event target
 */
const getEventTarget = (evt: React.ChangeEvent<HTMLInputElement>) => evt.currentTarget || evt.target

/**
 * Classname/style generator
 */
const useStyles: () => {
  root: string
  input: string
  sameRowInputContainer: string
  cardInput: string
} = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    margin: theme.spacing(1)
  },
  sameRowInputContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  cardInput: {
    margin: theme.spacing(1),
    display: 'flex'
  }
}))

type Props = {
  name: string
  pipeDiameter: PipeDiameter
  pipeMaterial: PipeMaterial
  open: boolean
  onClose: () => void
  addOrEdit: 'ADD' | 'EDIT'
  onSubmit: (data: { name: string; pipeDiameter: PipeDiameter; pipeMaterial: PipeMaterial }) => void
  loading: boolean
}

/**
 * Create Unit dialog
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Create Unit dialog
 */
export const FormDialog = (props: Props) => {
  const classes = useStyles()

  const [localName, setLocalName] = React.useState(props.name)
  const [localPipeDiameter, setLocalPipeDiameter] = React.useState(props.pipeDiameter)
  const [localPipeMaterial, setLocalPipeMaterial] = React.useState(props.pipeMaterial)

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{`${props.addOrEdit === 'ADD' ? 'Add' : 'Edit'} Unit`}</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Form
            className={classes.cardInput}
            name={localName}
            onNameChange={setLocalName}
            onPipeSizeChange={setLocalPipeDiameter}
            onPipeTypeChange={setLocalPipeMaterial}
            pipeSize={localPipeDiameter}
            pipeMaterial={localPipeMaterial}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <SWLoadingButton
          onClick={() =>
            props.onSubmit({
              name: localName,
              pipeDiameter: localPipeDiameter,
              pipeMaterial: localPipeMaterial
            })
          }
          loading={props.loading}
        >
          {props.addOrEdit === 'ADD' ? 'Add' : 'Save'}
        </SWLoadingButton>
      </DialogActions>
    </Dialog>
  )
}
