import { ReactNode } from 'react'
import { Box } from '@mui/material'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { SWLoadingButton } from '../LoadingButton'

type props = {
  open: boolean
  handleClose: () => void
  title: string
  showBack?: boolean
  save?: (event) => Promise<void>
  loading?: boolean
  children?: ReactNode
}

export const ModalContainer = ({ open, handleClose, title, showBack = false, save, loading = false, children }: props) => {
  const saveData = () => {
    save && save(handleClose)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Card
          sx={{
            width: 580,
            height: 680,
            margin: 'auto',
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Box margin="20px" display="flex" alignContent="center" alignItems="center">
            {showBack && <ArrowBackIosIcon fontSize="small" onClick={handleClose} sx={{ cursor: 'pointer' }} />}
            <Typography variant="h6">{title}</Typography>
          </Box>
          <CardContent>
            <Box paddingX="60px">
              {children}
              {!!save && (
                <SWLoadingButton
                  sx={{
                    width: '100%',
                    mt: '40px',
                    mb: '10px'
                  }}
                  onClick={saveData}
                  loading={loading}
                >
                  Save
                </SWLoadingButton>
              )}
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </div>
  )
}
