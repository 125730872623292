import * as React from 'react'
import { Image, useTheme } from '@aws-amplify/ui-react'
import { useAdminAccess } from '../useAdminAccess'

export function Header() {
  const { tokens } = useTheme()
  const timerRef = React.useRef<number | null>(null)
  const countRef = React.useRef<number>(0)
  const { isAdminLogin } = useAdminAccess()

  const handleClickLogo = () => {
    if (isAdminLogin) return null
    countRef.current += 1

    if (countRef.current > 4) {
      console.log('You are going to login as a admin')
      window.location.href = `${window.location.origin}/#admin`
      window.location.reload()
    }

    if (!timerRef.current) {
      timerRef.current = window.setTimeout(() => {
        countRef.current = 0
        timerRef.current = null
      }, 1000)
    }
  }

  return <Image alt="logo" src="/logo-light.png" padding={tokens.space.medium} onClick={handleClickLogo} />
}
