import { ErrorBoundary } from '../ErrorBoundary'
import { Box } from '@mui/material'
import { format } from 'date-fns'
import { formatNumber, dateStringToDate } from '../../util'
import { theme } from '../../theme'
import { Button } from '../Button'
import { saveAs } from 'file-saver'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
  VictoryTooltip,
  VictoryContainer,
  VictoryLabelProps
} from 'victory'

import type { Data } from './types'
import useMixPanel from '@/hooks/useMixPanel'

type Props = {
  createCSV?: (arg0: Data) => Promise<string>
  csvTitle?: string
  eventType?: string
  eventData?: {}
  data: Data
  title?: VictoryLabelProps['text']
  x: string
  y: string
  style?: {}
  yAxisType?: string
}

export const Chart = (props: Props): JSX.Element => {
  const domainPadding = props.data.length <= 40 ? 40 - props.data.length : 10
  const { yAxisType = 'gal' } = props
  const { trackEvent } = useMixPanel()

  return (
    <ErrorBoundary>
      <VictoryChart
        height={450}
        width={700}
        domainPadding={domainPadding}
        theme={VictoryTheme.material}
        animate={{ duration: 2000, onLoad: { duration: 1000 } }}
        containerComponent={<VictoryContainer responsive={false} style={{ margin: '0 auto', ...props.style }} />}
        key={props.data.length > 0 ? props.data[0].date : 'default-key'}
      >
        {props.title && (
          <VictoryLabel text={props.title} x={165} y={10} dy={20} textAnchor="middle" style={{ fontSize: 14 }} />
        )}
        {/* Y-axis */}
        <VictoryAxis
          dependentAxis
          domain={[0, 20]}
          tickFormat={(x) => `${formatNumber(x)} ${yAxisType}`}
          style={{
            tickLabels: { fontSize: 12 }
          }}
        />
        <VictoryBar
          x={props.x}
          y={props.y}
          data={props.data}
          style={{ data: { fill: theme.palette.primary.main } }}
          labelComponent={
            <VictoryTooltip
              style={{
                strokeWidth: 1,
                fontSize: 12,
                fontFamily: theme.typography.fontFamily
              }}
            />
          }
        />
        {/* X-axis */}
        <VictoryAxis
          fixLabelOverlap={true}
          gridComponent={<span />}
          style={{
            tickLabels: { fontSize: 12 }
          }}
          tickValues={props.data.map((_, idx) => idx + 1)}
          tickFormat={props.data.map((i) => format(dateStringToDate(i.date), 'MMM do'))}
        />
      </VictoryChart>
      {props.createCSV && (
        <Box sx={{ width: 700, margin: 'auto', textAlign: 'end' }}>
          <Button
            sx={{ marginRight: '48px' }}
            onClick={() => {
              props.createCSV!(props.data).then((csv) =>
                saveAs(
                  new Blob([csv], { type: 'text/plain;charset=utf-8' }),
                  `${(props.csvTitle || 'csv-download').replace(/ /g, '_')}.csv`
                )
              )
              trackEvent(props.eventType, props.eventData)
            }}
            color="secondary"
          >
            Export CSV
          </Button>
        </Box>
      )}
    </ErrorBoundary>
  )
}
