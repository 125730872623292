import { ErrorBoundary } from '../ErrorBoundary'
import { makeStyles, useTheme } from '@mui/styles'
import Typography from '@mui/material/Typography'
import BeatLoader from 'react-spinners/BeatLoader'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#343736ff',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0
  },
  imgContainer: {
    maxWidth: "600px"
  },
  img:{
    width: "100%"
  },
  text: {
    color: theme.palette.primary.main,
    marginBottom: 16
  }
}))

type Props = {
  text?: string
}

const InitialLoader = ({ text }: Props): JSX.Element => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <ErrorBoundary>
      <div className={classes.root}>
        <div className={classes.imgContainer}>
          <img className={classes.img} src="/logo.png" alt="Simple Water sub" />
        </div>
        <Typography variant="h5" className={classes.text}>
          {text}
        </Typography>
        <BeatLoader color={theme.palette.primary.main} loading={true} size={20} speedMultiplier={0.7} />
      </div>
    </ErrorBoundary>
  )
}

export default InitialLoader
