import { Edit as EditIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'

type props = {
  clickHandle: () => void
}

export const EditPencilIcon = ({ clickHandle }: props) => {
  return (
    <IconButton aria-label="edit" size="large" onClick={clickHandle}>
      <EditIcon fontSize="small" />
    </IconButton>
  )
}
