// @flow
/** @file Structure form component */

import { CreateStructure } from './CreateStructure'
import { CreateUnits } from './CreateUnits'
import makeStyles from '@mui/styles/makeStyles'
import { Review } from './Review'
import { StructureCreated } from './StructureCreated'
import { Welcome } from './Welcome'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'

import type { Address as AddressType } from '../../types'
import type { PipeDiameter, PipeMaterial, Unit } from '../../Units/types'

// Styling ////////////////////////////

/**
 * Function to generate classNames
 */
const useStyles: () => {
  root: string
  wrapper: string
  paper: string
  swipeableViewsContainer: string
} = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
    width: '100%'
  },
  wrapper: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex'
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    padding: theme.spacing(1),
    minWidth: '100%'
  },
  swipeableViewsContainer: {}
}))

type ComponentProps = {
  currentAddress: AddressType
  numberOfUnits: number
  rateMethod: 'SIMPLESUB' | 'UTILITY'
  setRateMethod: (val: 'SIMPLESUB' | 'UTILITY') => void
  onAddAnother: () => void
  onUnitNameChange: (id: string, name: string) => void
  onUnitPipeSizeChange: (id: string, size: PipeDiameter) => void
  onUnitPipeTypeChange: (id: string, type: PipeMaterial) => void
  sectionIndex: number
  setCurrentAddress: (Object) => void
  setNumberOfUnits: (num: number) => void
  setStructureName: (string) => void
  state: Object
  structureName: string
  unitErrorObj: { [id: string]: boolean }
  units: Unit[]
  timeZone: string
  onTimeZoneChange: (value: string) => void
}

// Component //////////////////////////

/**
 * Component to enter structure data
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Structure Form Component
 */
export const Component = (props: ComponentProps) => {
  const classes = useStyles()

  const welcomeRef = React.useRef<HTMLDivElement>(null)
  const createStructureRef = React.useRef(null)
  const createUnitsRef = React.useRef(null)
  const structureCreatedRef = React.useRef<HTMLDivElement>(null)
  const swipeableViewsContainerRef = React.useRef<HTMLDivElement>(null)
  const formRef = React.useRef<HTMLFormElement>(null)
  const rootRef = React.useRef<HTMLDivElement>(null)

  /**
   * DELETE
   */
  const resetScroll = () => {
    const scrollOpts: ScrollToOptions = { top: 0, behavior: 'auto' }
    // debugger
    if (welcomeRef.current) {
      welcomeRef.current.scrollTo(scrollOpts)
    }
    if (structureCreatedRef.current) {
      structureCreatedRef.current.scrollTo(scrollOpts)
    }
    if (swipeableViewsContainerRef.current) {
      swipeableViewsContainerRef.current.scrollTo(scrollOpts)
    }
    if (formRef.current) {
      formRef.current.scrollTo(scrollOpts)
    }
    if (rootRef.current) {
      rootRef.current.scrollTo(scrollOpts)
    }
  }

  resetScroll()

  return (
    <div className={classes.root} ref={rootRef}>
      <form ref={formRef}>
        <div className={classes.swipeableViewsContainer} ref={swipeableViewsContainerRef}>
          <SwipeableViews animateHeight index={props.sectionIndex} onSwitching={resetScroll}>
            {/* 0: Welcome */}
            <div className={classes.paper} ref={welcomeRef}>
              <Welcome />
            </div>
            {/* 1: Create Structure */}
            <div className={classes.paper} ref={createStructureRef}>
              <CreateStructure
                name={props.structureName}
                numberOfUnits={props.numberOfUnits}
                street={props.currentAddress.street}
                street2={props.currentAddress.street2}
                city={props.currentAddress.city}
                state={props.currentAddress.state}
                zip={props.currentAddress.zip}
                timeZone={props.timeZone}
                onTimeZoneChange={(i) => props.onTimeZoneChange(i)}
                onStreetChange={(val) => {
                  props.setCurrentAddress({
                    ...props.currentAddress,
                    street: val
                  })
                }}
                onStreet2Change={(val) => {
                  props.setCurrentAddress({
                    ...props.currentAddress,
                    street2: val
                  })
                }}
                onCityChange={(val) => {
                  props.setCurrentAddress({
                    ...props.currentAddress,
                    city: val
                  })
                }}
                onStateChange={(val) => {
                  props.setCurrentAddress({
                    ...props.currentAddress,
                    state: val
                  })
                }}
                onZipChange={(val) => {
                  props.setCurrentAddress({ ...props.currentAddress, zip: val })
                }}
                onNameChange={(val) => {
                  props.setStructureName(val)
                }}
                onNumberOfUnitsChange={(val) => {
                  if (val === '') {
                    props.setNumberOfUnits(1)
                  } else {
                    props.setNumberOfUnits(Number(val))
                  }
                }}
                rateMethod={props.rateMethod}
                setRateMethod={props.setRateMethod}
              />
            </div>
            {/* 2: Create Units */}
            <div ref={createUnitsRef}>
              <CreateUnits
                onNameChange={props.onUnitNameChange}
                unitErrorObj={props.unitErrorObj}
                units={props.units}
              />
            </div>
            {/* 3: Review */}
            <div ref={structureCreatedRef}>
              <Review structureName={props.structureName} structureAddress={props.currentAddress} units={props.units} />
            </div>
            {/* 4: Create */}
            <div ref={structureCreatedRef}>
              <StructureCreated
                onAddAnother={props.onAddAnother}
                structureName={props.structureName}
                structureAddress={props.currentAddress}
                units={props.units}
              />
            </div>
          </SwipeableViews>
        </div>
      </form>
    </div>
  )
}
