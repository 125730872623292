import TimeZoneSelector from '@/components/TimeZoneSelector'
import { FormControl, FormControlLabel, FormLabel, Switch, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as React from 'react'

/**
 * Get correct event target
 *
 * @param  {Event} evt - Event
 * @returns {HTMLElement} - Resolved event target
 */
const getEventTarget = (evt) => evt.currentTarget || evt.target

/**
 * Classname/style generator
 */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    margin: theme.spacing(1),
    marginTop: '10px'
  },
  sameRowInputContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },
  sameRowInput: {
    minWidth: theme.spacing(17)
  },
  textHeader: {
    textAlign: 'center',
    marginTop: '10px'
  }
}))

type Props = {
  name?: string
  onNameChange: (string) => void
  street?: string
  street2?: string
  city?: string
  state?: string
  zip?: string
  timeZone: string
  onStreetChange: (string) => void
  onStreet2Change: (string) => void
  onCityChange: (string) => void
  onStateChange: (string) => void
  onZipChange: (string) => void
  onTimeZoneChange: (string) => void
  numberOfUnits: number
  onNumberOfUnitsChange: (string) => void
  rateMethod: 'SIMPLESUB' | 'UTILITY'
  setRateMethod: (val: 'SIMPLESUB' | 'UTILITY') => void
}

/**
 * Structure Entry
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Address Entry
 */
export const CreateStructure = (props: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.textHeader}>
        <Typography variant="h4">Property Details</Typography>
        <Typography variant="subtitle1">Please provide information about your property.</Typography>
      </div>
      <TextField
        className={classes.input}
        label="Property name"
        name={'name'}
        required={true}
        value={props.name}
        InputLabelProps={{ shrink: true }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          props.onNameChange(getEventTarget(evt).value)
        }}
      />
      <TextField
        className={classes.input}
        label="Property Address"
        name={'street'}
        required={true}
        value={props.street}
        InputLabelProps={{ shrink: true }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          props.onStreetChange(getEventTarget(evt).value)
        }}
      />
      <TextField
        className={classes.input}
        label={'Unit/Suite (optional)'}
        name={'street2'}
        value={props.street2}
        InputLabelProps={{ shrink: true }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => props.onStreet2Change(getEventTarget(evt).value)}
      />
      <TextField
        className={classes.input}
        label={'City'}
        name={'city'}
        required={true}
        value={props.city}
        InputLabelProps={{ shrink: true }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => props.onCityChange(getEventTarget(evt).value)}
      />
      <TextField
        className={classes.input}
        label={'State'}
        name={'state'}
        required={true}
        value={props.state}
        InputLabelProps={{ shrink: true }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => props.onStateChange(getEventTarget(evt).value)}
      />
      <TextField
        className={classes.input}
        label={'Zip Code'}
        name={'zip'}
        required={true}
        value={props.zip}
        InputLabelProps={{ shrink: true }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          props.onZipChange(getEventTarget(evt).value)
        }}
      />
      <TimeZoneSelector
        value={props.timeZone}
        onChange={(i) => props.onTimeZoneChange(i)}
        sx={{ margin: '8px !important' }}
      />
      <TextField
        className={`${classes.input} ${classes.sameRowInput}`}
        label={'Number of units'}
        name={'units'}
        type={'number'}
        value={props.numberOfUnits}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          props.onNumberOfUnitsChange(getEventTarget(evt).value)
        }}
      />
      <FormControl component="fieldset" variant="standard">
        <FormLabel>
          Will the property be fully metered by SimpleSUB Water, including irrigation and common areas (recommended)?
        </FormLabel>
        <FormControlLabel
          control={
            <Switch
              checked={props.rateMethod === 'SIMPLESUB'}
              onChange={(event) => props.setRateMethod(event.target.checked ? 'SIMPLESUB' : 'UTILITY')}
              name="rateMethod"
            />
          }
          label={props.rateMethod === 'SIMPLESUB' ? 'YES' : 'NO'}
        />
      </FormControl>
    </div>
  )
}
