import { ServerDevice } from '@/Devices/types'
import { Box, InputAdornment, TextField, styled } from '@mui/material'
import { DEVICE_SETTINGS_CONSTANTS } from '../DeviceStatusTable'

type props = {
  device: ServerDevice | undefined
  updateDeviceData: (value: boolean | string, key: string) => void
}

const TextCustom = styled('p')({
  textAlign: 'center'
})

export const LeakAlert = ({ device, updateDeviceData }: props) => {
  return (
    <Box>
      <TextCustom>
        Notify me if water is running constantly for <b>{device?.leak_threshold_hours} hours</b>
      </TextCustom>
      <TextCustom>
        You will be notified every day as long as this condition exists.
      </TextCustom>
      <Box mt="25px" display="flex" justifyContent="center" alignItems="center">
        <TextField
          id="outlined-select-currency"
          label="Leak Duration"
          type="number"
          variant="standard"
          value={device?.leak_threshold_hours}
          sx={{ width: 180 }}
          onChange={(value) => updateDeviceData(value.target.value, 'leak_threshold_hours')}
          InputProps={{
            endAdornment: <InputAdornment position="end">hours</InputAdornment>,
            inputProps: { 
              max: DEVICE_SETTINGS_CONSTANTS.LEAK_THR_HOURS_MAX, 
              min: DEVICE_SETTINGS_CONSTANTS.LEAK_THR_HOURS_MIN 
            }
          }}
        />
      </Box>
    </Box>
  )
}
