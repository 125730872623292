import { addressIsValid, createUnits, getNextIndex, getPrevIndex, isUnitValid } from '../util'
import { Button } from '../../components/Button'
import { Buttons } from './Buttons'
import { Component } from './Component'
import { create as createStructure } from '../api'
import { create as createUnit } from '../../Units/api'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { getUserId } from '../../auth'
import { initialValue, reducer, actions } from './reducer'
import makeStyles from '@mui/styles/makeStyles'
import { useStructures } from '../useStructures'
import { useTheme } from '@mui/material/styles'
import { v4 as uuid } from 'uuid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

// Types
import type { Address } from '../../types'
import type { Structure } from '../types'
import type { PipeDiameter, PipeMaterial, Unit } from '../../Units/types'
import useMixPanel from '@/hooks/useMixPanel'

// Util ///////////////////////////////////////////////////////////////////////

const emptyAddress: Address = {
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: ''
}

// Steps in form
const WELCOME = 0
const STRUCTURE = 1
const UNITS = 2
const REVIEW = 3
const CREATE = 4

const buttonLabels = [
  /* 0 */ 'Welcome',
  /* 1 */ 'Property Details',
  /* 2 */ 'Unit Details',
  /* 3 */ 'Review',
  /* 4 */ 'Create',
  /* 5 */ 'Done'
]

const create = (userId: string, structure: Structure, units: Unit[]) => {
  const clonedStruct = {
    name: structure.name,
    address: structure.address,
    ix: structure.ix,
    rate_method: structure.rate_method,
    timezone_key: structure.timezone_key
  }
  // Create structure
  return createStructure(userId, clonedStruct).then((structure: Structure) => {
    // Create units
    return (
      Promise.all(units.map((unit, index) => createUnit(userId, structure.id, structure.address, unit.name, index)))
        // Combine returned data
        .then((units) => {
          return { structure, units }
        })
    )
  })
}

// Sub Components /////////////////////////////////////////////////////////////

/**
 * Error dialog
 *
 * @param {object} props - Component properties
 * @param {boolean} props.open - Whether or not dialog is open
 * @param {Function} props.onClose - Callback for close request
 * @param {string} props.title - Dialog title
 * @returns {React.Component} - Dialog
 */
const ErrorDialog = (props: { open: boolean; onClose: () => any; title?: string }) => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>{'The issue has been logged and recorded'}</DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color={'secondary'}>
        {'Close'}
      </Button>
    </DialogActions>
  </Dialog>
)

// Component //////////////////////////////////////////////////////////////////

const structureFormDialogClasses = makeStyles({
  buttonContainer: {
    justifyContent: 'space-between'
  },
  firstButtonContainer: {
    justifyContent: 'center'
  },
  lastButtonContainer: {
    justifyContent: 'right'
  }
})

type Props = {
  open?: boolean
  onClose?: () => void
}

/**
 * StructureForm
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Structure form
 */
export const StructureForm = ({ open, onClose = () => {} }: Props): JSX.Element => {
  // Constants ////////////////////////

  const classes = structureFormDialogClasses()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  // Data /////////////////////////////

  const { structures, mutate } = useStructures()

  // State ////////////////////////////

  const [state, dispatch] = React.useReducer(reducer, initialValue)
  const [stepIndex, setStepIndex] = React.useState(WELCOME)
  const [currentAddress, setCurrentAddress] = React.useState(emptyAddress)
  const [currentStructureId, setCurrentStructureId] = React.useState<string | null>(null)
  const [structureName, setStructureName] = React.useState('')
  const [numberOfUnits, setNumberOfUnits] = React.useState(1)
  const [unitErrorObj, setUnitErrorObj] = React.useState({})
  const [isStepValid, setIsStepValid] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const [rateMethod, setRateMethod] = React.useState<'SIMPLESUB' | 'UTILITY'>('SIMPLESUB')
  const [createLoading, setCreateLoading] = React.useState(false)
  const [timeZone, setTimeZone] = React.useState<string>('US/Eastern')
  const { trackEvent } = useMixPanel()

  const isValid = React.useCallback(
    (index: number) => {
      // Welcome
      if (index === WELCOME) {
        return true
      }
      // Structure
      else if (index === STRUCTURE) {
        return Boolean(addressIsValid(currentAddress) && structureName && numberOfUnits && numberOfUnits > 0)
      }
      // Units
      else if (index === UNITS) {
        return Boolean(currentStructureId && setUnitsValid(state.structures[currentStructureId].units))
      }
      // Review
      else if (index === REVIEW) {
        return true
      }
      // Create
      else if (index === CREATE) {
        return true
      }
      return false
    },
    [currentAddress, currentStructureId, numberOfUnits, structureName, state.structures]
  )

  // Effects //////////////////////////

  // Re-run validation
  React.useEffect(() => {
    setIsStepValid(isValid(stepIndex))
  }, [stepIndex, isValid])

  // Reset units if user goes back to edit number of units
  React.useEffect(() => {
    if (currentStructureId) {
      const newUnits = createUnits(numberOfUnits)

      dispatch({
        type: 'SET_UNITS',
        payload: { structureId: currentStructureId, units: newUnits }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfUnits])

  // Util /////////////////////////////

  /**
   * Given an an object of units, determine if they're all valid
   *
   * @param {object} units - An object of Units
   * @returns {boolean} - Wether or not all units are valid
   */
  const setUnitsValid = (units) => {
    let areAllUnitsValid = true
    const unitErrorObj = units.reduce((acc, cur) => {
      const valid = isUnitValid(cur)
      if (!valid) {
        areAllUnitsValid = false
      }
      return { ...acc, [cur.id]: !valid }
    }, {})

    setUnitErrorObj(unitErrorObj)
    return areAllUnitsValid
  }

  /** Reset form */
  const onAddAnother = () => {
    setStepIndex(1)
    setCurrentStructureId(null)
    setStructureName('')
    setNumberOfUnits(1)
    setCurrentAddress(emptyAddress)
    setRateMethod('SIMPLESUB')
  }

  const handlePropertyEvents = async ({ structure, units }: { structure: Structure; units: Unit[] }) => {
    await trackEvent('Property Created', {
      PropertyName: structure.name,
      PropertyID: structure.id
    })
    await units.map(async (e) => {
      await trackEvent('Unit Created', {
        PropertyName: structure.name,
        PropertyID: structure.id,
        UnitName: e.name,
        UnitID: e.id
      })
    })
    return
  }

  /** Handle next click */
  const onNext = async () => {
    // Only move forward if section is valid
    if (isStepValid) {
      // Welcome
      if (stepIndex === WELCOME) {
        setStepIndex(getNextIndex(stepIndex, buttonLabels.length))
      }
      // Create structure
      else if (stepIndex === STRUCTURE) {
        if (!currentStructureId || !state.structures[currentStructureId]) {
          const id = uuid()

          dispatch({
            type: actions.CREATE_STRUCTURE,
            payload: {
              name: structureName,
              address: currentAddress,
              numberOfUnits,
              units: createUnits(numberOfUnits),
              id,
              rate_method: rateMethod,
              timeZone: timeZone
            }
          })

          setCurrentStructureId(id)
        }
        setCurrentAddress(currentAddress)
        setStepIndex(getNextIndex(stepIndex, buttonLabels.length))
      }
      // Setup units
      else if (stepIndex === UNITS) {
        setCurrentAddress(currentAddress)
        setStepIndex(getNextIndex(stepIndex, buttonLabels.length))
      }
      // Review
      else if (stepIndex === REVIEW) {
        if (currentStructureId && state.structures[currentStructureId]) {
          setCreateLoading(true)
          const userId = await getUserId()
          create(
            userId,
            {
              ...state.structures[currentStructureId],
              ix: structures?.length || 0,
              timezone_key: timeZone
            },
            state.structures[currentStructureId].units
          )
            .then(async (data) => {
              await handlePropertyEvents(data)
              setStepIndex(getNextIndex(stepIndex, buttonLabels.length))
            })
            .then(() => mutate())
            .catch(() => setErrorMessage('Trouble creating property'))
            .finally(() => {
              setCreateLoading(false)
            })
        } else {
          throw new Error('MISSING_STRUCTURE_ID_OR_STRUCTURE')
        }
      }
      // Done screen
      else if (stepIndex === CREATE) {
        onClose()
        setStepIndex(WELCOME)
        setCurrentStructureId(null)
        setStructureName('')
        setNumberOfUnits(1)
        setCurrentAddress(emptyAddress)
        setRateMethod('SIMPLESUB')
        dispatch({
          type: actions.RESET_STRUCTURE,
          payload: null
        })
      }
    }
  }

  /** Handle prev click */
  const onPrev = () => {
    setStepIndex(getPrevIndex(stepIndex, 0))
  }

  /**
   * Handle unit name change
   *
   * @param  {string} unitId  - Unit ID
   * @param  {string} newName - New name for the unit
   * @throws Will throw `CANT_FIND_UNIT_TO_EDIT_NAME` if the provided unit ID can't be found
   */
  const onUnitNameChange = (unitId, newName) => {
    const currentUnit =
      currentStructureId && state.structures[currentStructureId].units.find((unit) => unit.id === unitId)
    if (currentUnit) {
      dispatch({
        type: actions.SET_UNIT,
        payload: {
          structureId: currentStructureId,
          unitId,
          unitData: { ...currentUnit, name: newName }
        }
      })
      setIsStepValid(isValid(stepIndex))
    } else {
      throw new Error('CANT_FIND_UNIT_TO_EDIT_NAME')
    }
  }

  /**
   * Handle user changing the pipe size on a single unit
   *
   * @param  {string} unitId      - Unit ID to update
   * @param  {number} newPipeSize - New pipe size
   * @throws Will throw `CANT_FIND_UNIT_TO_EDIT_PIPESIZE` if the provided unit ID can't be found
   */
  const onUnitPipeSizeChange = (unitId: string, newPipeSize: PipeDiameter) => {
    const currentUnit =
      currentStructureId && state.structures[currentStructureId].units.find((unit) => unit.id === unitId)
    if (currentUnit) {
      dispatch({
        type: actions.SET_UNIT,
        payload: {
          structureId: currentStructureId,
          unitId,
          unitData: { ...currentUnit, pipeSize: newPipeSize }
        }
      })
    } else {
      throw new Error('CANT_FIND_UNIT_TO_EDIT_PIPESIZE')
    }
  }

  /**
   * Handle user changing the pipe type on a single unit
   *
   * @param  {string} unitId      - Unit ID to update
   * @param  {number} newPipeType - New pipe type
   * @throws Will throw `CANT_FIND_UNIT_TO_EDIT_PIPESIZE` if the provided unit ID can't be found
   */
  const onUnitPipeTypeChange = (unitId: string, newPipeType: PipeMaterial) => {
    const currentUnit =
      currentStructureId && state.structures[currentStructureId].units.find((unit) => unit.id === unitId)
    if (currentUnit) {
      dispatch({
        type: actions.SET_UNIT,
        payload: {
          structureId: currentStructureId,
          unitId,
          unitData: { ...currentUnit, pipeMaterial: newPipeType }
        }
      })
    } else {
      throw new Error('CANT_FIND_UNIT_TO_EDIT_PIPESIZE')
    }
  }

  // Component ////////////////////////
  return (
    <ErrorBoundary>
      <Dialog
        fullScreen={fullScreen}
        aria-labelledby="structure-create-form-dialog"
        open={!!open}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paper': {
            padding: stepIndex === WELCOME ? '55px 15px' : '5px'
          }
        }}
      >
        <DialogTitle id="structure-create-form-dialog">
          <Typography variant="h4" textAlign="center">
            {stepIndex === WELCOME ? 'Add a new property' : ''}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Component
            onAddAnother={onAddAnother}
            currentAddress={currentAddress}
            numberOfUnits={numberOfUnits}
            sectionIndex={stepIndex}
            rateMethod={rateMethod}
            setRateMethod={setRateMethod}
            setCurrentAddress={setCurrentAddress}
            setNumberOfUnits={setNumberOfUnits}
            setStructureName={setStructureName}
            state={state}
            structureName={structureName}
            unitErrorObj={unitErrorObj}
            units={currentStructureId ? state.structures[currentStructureId].units : []}
            onUnitNameChange={onUnitNameChange}
            onUnitPipeSizeChange={onUnitPipeSizeChange}
            onUnitPipeTypeChange={onUnitPipeTypeChange}
            timeZone={timeZone}
            onTimeZoneChange={(i) => setTimeZone(i)}
          />
        </DialogContent>
        <DialogActions
          className={
            stepIndex === 0
              ? classes.firstButtonContainer
              : stepIndex === 4
              ? classes.lastButtonContainer
              : classes.buttonContainer
          }
        >
          <Buttons
            isNextDisabled={!isStepValid || createLoading}
            nextLabel={stepIndex ? buttonLabels[stepIndex + 1] : 'Get Started'}
            onNext={onNext}
            onPrev={onPrev}
            prevLabel={stepIndex !== 4 ? buttonLabels[stepIndex - 1] : ''}
            showNextChevron={stepIndex !== buttonLabels.length - 2}
            createLoading={createLoading}
          />
        </DialogActions>
      </Dialog>
      <ErrorDialog open={Boolean(errorMessage)} title={errorMessage} onClose={() => setErrorMessage(undefined)} />
    </ErrorBoundary>
  )
}

export default StructureForm
