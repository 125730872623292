import { Dialog, LinearProgress, Slide, Stack, Typography } from '@mui/material'
import React from 'react'
import { TransitionProps } from '@mui/material/transitions'
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface IProps {
  open: boolean
  title: string
  description: string
}

const ModalLoading = ({ open, title, description }: IProps) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{ textAlign: 'center' }}
    >
      <Stack justifyContent={'center'} margin={5} gap={2}>
        <img src="/img/invoice.png" height={56} width={56} style={{ margin: 'auto' }} alt="timeLogo" />
        <LinearProgress />
        <Typography fontSize={24} fontWeight={700}>
          {title}
        </Typography>
        <Typography fontSize={16} fontWeight={400}>
          {description}
        </Typography>
      </Stack>
    </Dialog>
  )
}

export default ModalLoading
