import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, IconButton, LinearProgress } from '@mui/material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {
  addDays,
  differenceInDays,
  endOfDay,
  endOfMonth,
  format,
  getUnixTime,
  isFuture,
  isToday,
  startOfDay,
  startOfMonth,
  subDays
} from 'date-fns'
import useSWR from 'swr'
import makeStyles from '@mui/styles/makeStyles'
import { DeleteConfirmDialog } from '@/components/Dialog'
import { Address } from './Address'
import { Chart } from '../../components/Chart'
import { createLabel as createChartLabel } from '../../components/Chart/util'
import { createStructureRoute } from '../../app-routes'
import { edit, remove } from '../api'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { addCreate, convertTimestampToTimeZone, formatNumber } from '../../util'
import { getTotalVolume } from '../../Usage/util'
import { useStructure } from '../../Structures/useStructures'
import { useUnit } from '../useUnit'
import { get as getUsage } from '../../Usage/api'
import theme from '../../theme'
import { generateUsageCSV } from '../util'
import { DateRange } from '@mui/lab/DateRangePicker'
import classNames from 'classnames'
import { Name } from './Name'
import type { Address as AddressType } from '../../types'
import {
  OUTAGE_STATUS_REFRESH_MILLIS,
  OUTAGE_STATUS_SWR_KEY,
  mapOutageStatusToIsElasticsearchOutage
} from '@/Outage/util'
import { defaultOutageStatusResponse, getOutageStatus } from '@/Outage/api'
import { DeviceStatusTable } from '@/components/DeviceStatusTable'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import useMixPanel from '@/hooks/useMixPanel'
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD'

// Component //////////////////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%'
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  header: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'left'
  },
  chartBody: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  chartLoader: {
    left: '20%',
    position: 'absolute',
    top: '50%',
    width: '60%'
  },
  editIcon: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  prevNextButton: {
    height: 'fit-content'
  },
  nextButton: {
    marginLeft: theme.spacing(1)
  },
  prevButton: {
    marginRight: theme.spacing(1)
  }
}))

const today = new Date()
const defaultSelected: DateRange<Date> = [startOfMonth(today), endOfMonth(today)]

export const Unit = (): JSX.Element => {
  const navigate = useNavigate()
  const classes = useStyles()

  const [loading, setLoading] = React.useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
  const [graphData, setGraphData] = React.useState([
    { label: '', gallons: 0, volume: 0, date: format(today, 'yyyy-MM-dd') }
  ])
  const [moveByMonth, setMoveByMonth] = React.useState(true)
  const [range, setRange] = React.useState<DateRange<Date>>(defaultSelected)
  const [canGoNext, setCanGoNext] = React.useState(false)
  const [timeZone, setTimeZone] = React.useState<string>('')
  const [eventData, setEventData] = React.useState<{}>()
  const [alredyTrackedEvent, setAlredyTrackedEvent] = React.useState(false)
  const { trackEvent } = useMixPanel()

  const { structureId, unitId } = useParams()

  if (!unitId || !structureId) {
    throw new Error('MISSING_REQUIRED_ROUTE_DATA')
  }

  /** Structure */
  const { structure } = useStructure(structureId)

  React.useEffect(() => {
    if (structure && timeZone === '') {
      if (structure.timezone_key !== '') setTimeZone(structure.timezone_key)
      if (structure.timezone_key === '') {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        setTimeZone(tz)
      }
    }
  }, [structure, timeZone])

  /** Unit, call to refresh unit data */
  const { unit, mutate: refreshData } = useUnit(unitId)

  React.useEffect(() => {
    if (structure && unit && !alredyTrackedEvent && range) {
      setAlredyTrackedEvent(true)
      trackEvent('Unit Usage Viewed', {
        PropertyID: structureId,
        UnitID: unitId,
        PropertyName: structure.name,
        UnitName: unit.name,
        StartDate: convertTimestampToTimeZone(getUnixTime(startOfDay(range[0]!)), undefined),
        EndDate: convertTimestampToTimeZone(getUnixTime(endOfDay(range[1]!)), undefined)
      })
      setEventData({
        UnitID: unitId,
        UnitName: unit.name,
        StartDate: convertTimestampToTimeZone(getUnixTime(startOfDay(range[0]!)), undefined),
        EndDate: convertTimestampToTimeZone(getUnixTime(endOfDay(range[1]!)), undefined)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structure, structureId, unitId, unit, range, timeZone, alredyTrackedEvent, range])

  const { data: outageStatusResponse = defaultOutageStatusResponse } = useSWR(OUTAGE_STATUS_SWR_KEY, getOutageStatus, {
    refreshInterval: OUTAGE_STATUS_REFRESH_MILLIS
  })

  // Load usage
  const { data: rawUsage } = useSWR(
    `units/${unitId}/usage?start=${getUnixTime(startOfDay(range[0]!))}&end=${getUnixTime(endOfDay(range[1]!))}`,
    () => getUsage(unitId, range[0]!, range[1]!, mapOutageStatusToIsElasticsearchOutage(outageStatusResponse), timeZone)
  )

  // Reset dates when unit changes
  React.useEffect(() => {
    setRange(defaultSelected)
  }, [unitId])

  // Set graph data when rawUsage changes
  React.useEffect(() => {
    const usageByDate: { [dateStr: string]: number } = {}

    rawUsage?.devices?.forEach((device) => {
      device.daily_usages.forEach((usageData) => {
        addCreate(usageByDate, usageData.date, usageData.volume)
      })
    })

    const cleanedUsage = Object.entries(usageByDate).map((u) => ({
      label: createChartLabel(u[1], u[0]),
      gallons: u[1],
      volume: u[1],
      date: u[0]
    }))

    // @ts-ignore
    if (cleanedUsage.length) {
      // @ts-ignore
      setGraphData(cleanedUsage)
    } else {
      // @ts-ignore
      setGraphData([{ label: '', gallons: 0, volume: 0, date: format(range[0], 'yyyy-MM-dd') }])
    }
  }, [rawUsage, range])

  // En/disable next button
  React.useEffect(() => {
    if (range[1]) {
      if (isFuture(range[1]) || isToday(range[1])) {
        setCanGoNext(false)
      } else {
        setCanGoNext(true)
      }
    }
  }, [range])

  const handleClickNext = () => {
    if (moveByMonth) {
      const newMonth = addDays(range[1]!, 3)
      setRange([startOfDay(startOfMonth(newMonth)), startOfDay(endOfMonth(newMonth))])
    } else {
      const numberOfDaysInRange = differenceInDays(range[1]!, range[0]!)

      const newFrom = addDays(range[0]!, numberOfDaysInRange + 1)
      const newTo = addDays(range[1]!, numberOfDaysInRange + 1)

      setAlredyTrackedEvent(false)
      setRange([startOfDay(newFrom), startOfDay(newTo)])
    }
  }

  const handleClickPrev = () => {
    if (moveByMonth) {
      const newMonth = subDays(range[0]!, 3)
      setRange([startOfDay(startOfMonth(newMonth)), startOfDay(endOfMonth(newMonth))])
    } else {
      const numberOfDaysInRange = differenceInDays(range[1]!, range[0]!)

      const newFrom = subDays(range[0]!, numberOfDaysInRange + 1)
      const newTo = subDays(range[1]!, numberOfDaysInRange + 1)

      setAlredyTrackedEvent(false)
      setRange([startOfDay(newFrom), startOfDay(newTo)])
    }
  }

  const handleDateChange = (dates) => {
    const startDateWithDayJs = dayjs(dates[0])
    const endDateWithDayJs = dayjs(dates[1])

    if (
      startDateWithDayJs.isSame(endDateWithDayJs.startOf('month'), 'day') &&
      endDateWithDayJs.isSame(endDateWithDayJs.endOf('month'), 'day')
    ) {
      setMoveByMonth(true)
    } else {
      setMoveByMonth(false)
    }

    const startDate = new Date(dates[0])
    const endDate = new Date(dates[1])

    startDate?.setHours(0, 0, 0, 0)
    endDate?.setHours(23, 59, 59, 0)

    setAlredyTrackedEvent(false)
    setRange([startDate, endDate])
  }

  const handleClickDeleteUnit = () => {
    setOpenDeleteConfirm(true)
  }

  const handleClickRemove = async () => {
    if (!unit) return

    setOpenDeleteConfirm(false)
    setLoading(true)
    await remove(unit.id)
    setLoading(false)
    navigate(createStructureRoute(structureId))
    refreshData()
  }

  const handleUpdateUnit = (fieldName: string) => async (value: string | AddressType) => {
    if (!unit) return
    setLoading(true)
    await edit({ ...unit, [fieldName]: value })
    refreshData()
    setLoading(false)
  }

  const isLoadingUsage = !graphData
  return (
    <ErrorBoundary>
      <div data-component-id={'UNIT-VIEW'} className={classes.wrapper}>
        {unit && (
          <div className={classes.container}>
            <div className={classes.header}>
              <div style={{ flexGrow: 1 }}>
                <Name name={unit?.name || ''} onUpdate={handleUpdateUnit('name')} onDelete={handleClickDeleteUnit} />
                <Address
                  address={
                    unit?.address || {
                      street: '',
                      street2: '',
                      city: '',
                      state: '',
                      zip: ''
                    }
                  }
                  onUpdate={handleUpdateUnit('address')}
                  loading={loading}
                />
                {structure && (
                  <DeviceStatusTable
                    devices={unit.devices}
                    refreshData={() => refreshData()}
                    timeZone={structure?.timezone_key || ''}
                    structure={structure}
                    unitInfo={{ unit_id: unit.id, unit_name: unit.name }}
                  />
                )}
              </div>
            </div>
            <Box position="relative" sx={{ marginTop: 2 }}>
              <Box>
                <Box textAlign="center" className="custom-range-picker" marginBottom="15px">
                  <RangePicker
                    format={'MMM D, YYYY'}
                    allowClear={false}
                    maxDate={dayjs(format(endOfMonth(today), 'yyyy-MM-dd'), dateFormat)}
                    onChange={(value, dateString) => handleDateChange(dateString)}
                    value={
                      range && range[0] && range[1]
                        ? [
                            dayjs(format(range[0], 'yyyy-MM-dd'), dateFormat),
                            dayjs(format(range[1], 'yyyy-MM-dd'), dateFormat)
                          ]
                        : undefined
                    }
                    presets={[
                      {
                        label: <span aria-label="Current month">Current month</span>,
                        value: () => [dayjs().startOf('month'), dayjs().endOf('month')]
                      }
                    ]}
                  />
                </Box>
                <Typography variant="h6" align="center" color={theme.palette.grey[700]}>
                  {formatNumber(getTotalVolume(graphData || []))} gal
                </Typography>
              </Box>
              <Box className={classes.chartBody}>
                <IconButton
                  size="large"
                  className={classNames(classes.prevNextButton, classes.prevButton)}
                  onClick={handleClickPrev}
                >
                  <ArrowLeftIcon fontSize="large" />
                </IconButton>
                <Box sx={{ opacity: isLoadingUsage ? 0.2 : undefined, display: 'flex', flexDirection: 'column' }}>
                  <Chart
                    data={graphData || []}
                    x={'date'}
                    y={'gallons'}
                    createCSV={() =>
                      Promise.resolve(
                        generateUsageCSV(rawUsage, {
                          start: getUnixTime(range[0]!),
                          end: getUnixTime(range[1]!),
                          // @ts-ignore - timeZoneName not recognized
                          timezone: new Date().toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ')[2]
                        })
                      )
                    }
                    eventType="Unit Usage Exported"
                    eventData={eventData}
                    csvTitle={`${
                      moveByMonth
                        ? format(startOfMonth(range[0]!), 'MM_dd_yyyy')
                        : `${format(range[0]!, 'MM_dd_yyyy')}-${format(range[1]!, 'MM_dd_yyyy')}`
                    }_${structure?.name}_${unit.name}_usage`}
                  />
                </Box>
                <IconButton
                  size="large"
                  className={classNames(classes.prevNextButton, classes.nextButton)}
                  onClick={handleClickNext}
                  disabled={!canGoNext}
                >
                  <ArrowRightIcon fontSize="large" />
                </IconButton>
                {isLoadingUsage && (
                  <div className={classes.chartLoader}>
                    <LinearProgress />
                  </div>
                )}
              </Box>
            </Box>
            <DeleteConfirmDialog
              open={openDeleteConfirm}
              onClose={() => setOpenDeleteConfirm(false)}
              onConfirm={handleClickRemove}
              title="Do you really want to delete this unit?"
              loading={loading}
            />
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}
