/** @file API logic for billing calls */

import { createDefaultHeaders, getJSONOrThrow, throwIfError } from '../api'
import { getSession, urlWithAdminQuery } from '../auth'
import changeCase from 'change-object-case'
import { Bill } from './types'
import { toast } from 'react-toastify'
import { log } from '../util'
import { StructureBilling } from '@/Structures/types'

// Constants //////////////////////////////////////////////////////////////////

const SEND_BILLS_DEFAULT_DAYS_UNTIL_DUE = 21

// Utility ////////////////////////////////////////////////////////////////////

/**
 * Process server response from get/list/update billing
 *
 * @param  {object} unNormalizedData - Server response
 * @returns {object} - Passed in object
 */
const handleBillingResponse = (unNormalizedData): any => {
  const camelCased = changeCase.camelArray(unNormalizedData)
  return camelCased
}

// API Calls //////////////////////////////////////////////////////////////////

// Create /////////////////////////////
type CreateObj = {
  start: number // 1598922000,
  end: number // 1601427600,
  volume: number // 12345.05,
  volume_units: string
  cost: number // 54321.01,
  structureId: string // "8f9bb99f-9ecc-4893-9db4-909188526d6a"
  rate_method: string
  effective_rate: number | null
}

/**
 * Create bill
 *
 * @param  {object} createObj - Data to create bill
 * @returns {Promise} - Result of call to create bill
 */
export const create = (createObj: CreateObj): any => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery('/billing', session.adminEmail), {
      method: 'POST',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      }),
      body: JSON.stringify({
        start_date: createObj.start,
        end_date: createObj.end,
        volume: createObj.volume,
        volume_units: createObj.volume_units,
        cost: createObj.cost,
        cost_units: 'DOLLARS',
        rate_method: createObj.rate_method,
        structure_id: createObj.structureId,
        effective_rate: createObj.effective_rate
      })
    })
      .then((resp) => resp.json())
      .catch((err) => {
        console.error(err)
        toast.error(`Error: Create billing for Property: ${createObj.structureId}`)
        return err
      })
  )
}

// Get ////////////////////////////////

/**
 * Get bills for structure
 *
 * @param  {string} structureId - ID for specififc structure to get bills for
 * @returns {Promise} - Result of call to get bill
 */
export const getForStructure = (structureId: string): Promise<Bill[]> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/billing/${structureId}`, session.adminEmail), {
      method: 'GET',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then((resp) => resp.json())
      .then(handleBillingResponse)
      .catch((err) => {
        console.error(err)
        toast.error(`Error: Getting billings for Property: ${structureId}`)
        return err
      })
  )
}

// Edit ///////////////////////////////

type EditObj = CreateObj & { id: string }

/**
 * Edit bill
 *
 * @param  {object} editObj - Data to edit bill
 * @returns {Promise} - Result of call to edit bill
 */
export const edit = (editObj: EditObj) => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/billing/${editObj.id}`, session.adminEmail), {
      method: 'PUT',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      }),
      body: JSON.stringify({
        start_date: editObj.start,
        end_date: editObj.end,
        volume: editObj.volume,
        volume_units: editObj.volume_units,
        cost: editObj.cost,
        cost_units: 'DOLLARS',
        rate_method: editObj.rate_method,
        structure_id: editObj.structureId,
        effective_rate: editObj.effective_rate
      })
    })
      .then((resp) => resp.json())
      .catch((err) => {
        console.error(err)
        toast.error(`Error: Editing billings for Property: ${editObj.structureId}`)
        return err
      })
  )
}

// Remove /////////////////////////////////////////////////////////////////////

/**
 * Remove Bill
 *
 * @param  {string} billId - Bill ID
 * @returns {Promise} - Result of call to remove bill
 */
export const remove = (billId: string): Promise<void> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/billing/${billId}`, session.adminEmail), {
      method: 'DELETE',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    }).catch((err) => {
      console.error(err)
      toast.error(`Error: Deleting billings for id: ${billId}`)
      return err
    })
  )
}

// Generate bill details /////////////////////////////////////////////////////////////////////

export const generateBill = (billId: string, isElasticsearchOutage: boolean): Promise<StructureBilling> => {
  if (isElasticsearchOutage) {
    const outageError = new Error(`Under maintenance! The billing generation system is currently unavailable.`)
    toast.error(outageError.message)
    return Promise.reject(outageError)
  }

  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/billing/generate/${billId}`, session.adminEmail), {
      method: 'GET',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(throwIfError)
      .then((resp) => resp.json())
      .catch((err) => {
        const reqId = err.headers.get('request-id')
        const newErr = new Error(`Error Generating structure billing [requestId: ${reqId}]`)
        toast.error(newErr.message)
        log.error(newErr)
        throw newErr
      })
  )
}

// sendBills /////////////////////////////////////////////////////////////////////

export const sendBills = (billId: string): Promise<Bill> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/billing/${billId}/sendBills`, session.adminEmail), {
      method: 'POST',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      }),
      body: JSON.stringify({
        days_until_due: SEND_BILLS_DEFAULT_DAYS_UNTIL_DUE, 
      })
    })
      .then(getJSONOrThrow)
      .catch((err) => {
        const newErr = new Error(`Error sending bills: ${err.message}`)
        toast.error(newErr.message)
        log.error(newErr)
      })
  )
}